import { Routes, Route } from 'react-router-dom'
import _ from 'lodash'
import NotFound from 'core/NotFound'
import { AuthenticatedRoute } from 'core/RRoute'
import Dashboard from 'pages/Dashboard'
import ConnectTikTokAccount from 'pages/ShopManagement/ConnectTiktokAccount'
import ProductManagement from 'pages/ProductManagement'
import ShopHealth from 'pages/ShopManagement/ShopHealth'
import SettingAccount from 'pages/AccountManagement'
import ListingManagement from 'pages/ListingProduct/ListingManagement'
import ProductTemplate from 'pages/ProductTemplate'
import NicheManagement from 'pages/NicheManagement'
import StaffManagement from 'pages/StaffManagement'
import AutoIdea from '../../pages/ListingProduct/AutoIdea'
import { CODE_PERMISSION } from 'const/permission'
import TemplateSetting from 'pages/TemplateSetting'
import PromotionManagement from 'pages/PromotionManagement'

export default function Router() {
    return (
        <Routes>
            <Route element={<AuthenticatedRoute required={CODE_PERMISSION.PRODUCT_MANAGEMENT} />}>
                <Route path='/dashboard' element={<Dashboard />} />
            </Route>
            <Route element={<AuthenticatedRoute required={CODE_PERMISSION.PRODUCT_MANAGEMENT} />}>
                <Route path='/quan-ly-san-pham' element={<ProductManagement />} />
            </Route>
            <Route element={<AuthenticatedRoute required={CODE_PERMISSION.TEMPLATE_MANAGEMENT} />}>
                <Route path='/quan-ly-template' element={<ProductTemplate />} />
            </Route>
            <Route element={<AuthenticatedRoute required={CODE_PERMISSION.NICHE_MANAGEMENT} />}>
                <Route path='/quan-ly-niche' element={<NicheManagement />} />
            </Route>
            <Route element={<AuthenticatedRoute required={CODE_PERMISSION.LISTING_MANAGEMENT} />}>
                <Route path='/quan-ly-listing' element={<ListingManagement />} />
            </Route>
            <Route element={<AuthenticatedRoute required={CODE_PERMISSION.LISTING_MANAGEMENT} />}>
                <Route path='/listing-auto-idea' element={<AutoIdea />} />
            </Route>
            <Route element={<AuthenticatedRoute required={CODE_PERMISSION.LISTING_MANAGEMENT} />}>
                <Route path='/quan-ly-promotion' element={<PromotionManagement />} />
            </Route>
            <Route element={<AuthenticatedRoute required={CODE_PERMISSION.SHOP_HEALTH} />}>
                <Route path='/tinh-trang-shop' element={<ShopHealth />} />
            </Route>
            <Route element={<AuthenticatedRoute required={CODE_PERMISSION.CONNECT_SHOP} />}>
                <Route path='/ket-noi-shop-tiktok' element={<ConnectTikTokAccount />} />
            </Route>
            <Route element={<AuthenticatedRoute required={CODE_PERMISSION.STAFF_MANAGEMENT} />}>
                <Route path='/quan-ly-nhan-vien' element={<StaffManagement />} />
            </Route>
            <Route element={<AuthenticatedRoute required={CODE_PERMISSION.STAFF_MANAGEMENT} />}>
                <Route path='/cai-dat-tai-khoan' element={<SettingAccount />} />
            </Route>
            <Route element={<AuthenticatedRoute required={CODE_PERMISSION.STAFF_MANAGEMENT} />}>
                <Route path='/cai-dat-template' element={<TemplateSetting />} />
            </Route>
            <Route element={<AuthenticatedRoute />}>
                <Route path='*' element={<NotFound />} />
            </Route>
        </Routes>
    )
}
