import { Table, Row, Space, Button } from 'antd'
import { useEffect, useState, useCallback } from 'react'
import { EditOutlined, ReloadOutlined, DeleteOutlined } from '@ant-design/icons'
import { blue, red } from '@ant-design/colors'
import moment from 'moment'
import _ from 'lodash'
import { notify } from 'contexts/Notification'
import { ColumnsType } from 'antd/lib/table'
import { IDescriptionTemplate } from 'types/IDescriptionTemplate'
import DescriptionTemplateAPI from 'api/DescriptionTemplateAPI'
import DescriptionTemplateModal from './modals/DescriptionTemplateModal'

const DescriptionTemplate = ({ tab }: { tab: number }) => {
    const [descriptionTemplates, setDescriptionTemplates] = useState<IDescriptionTemplate[]>([])
    const [loading, setLoading] = useState(false)
    const [pageSize, setPageSize] = useState(50)
    const [pageNumber, setPageNumber] = useState(1)
    const [total, setTotal] = useState(0)
    const [visibleModal, setVisibleModal] = useState(false)
    const [editedItem, setEditedItem] = useState<IDescriptionTemplate>()

    const getDescriptionTemplates = (query: { page_size: number; page_number: number; search?: string }) => {
        setLoading(true)
        DescriptionTemplateAPI.getDescriptionTemplates(query)
            .then((res) => {
                if (res.pagination && res.pagination.total >= 0) {
                    setTotal(res.pagination.total)
                }
                setDescriptionTemplates(res.data)
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (tab === 3) {
            refetchData()
        }
    }, [tab])

    const refetchData = () => {
        getDescriptionTemplates({
            page_number: pageNumber,
            page_size: pageSize
        })
    }

    const handleChangePagination = (_pageNumber: number, _pageSize: number) => {
        if (pageSize !== _pageSize) {
            setPageSize(_pageSize)
            getDescriptionTemplates({
                page_number: pageNumber,
                page_size: _pageSize
            })
        } else if (pageNumber !== _pageNumber) {
            setPageNumber(_pageNumber)
            getDescriptionTemplates({
                page_number: _pageNumber,
                page_size: pageSize
            })
        }
    }

    const toggleAddModal = useCallback(
        (value: boolean) => {
            setVisibleModal(value)
            if (!value) {
                setEditedItem(undefined)
            }
        },
        [visibleModal]
    )

    const edit = (record: any) => {
        console.log('record', record)
        setEditedItem(record)
        toggleAddModal(true)
    }

    const onRemove = (_id: string) => {
        setLoading(true)
        DescriptionTemplateAPI.removeDescriptionTemplate(_id)
            .then(() => {
                notify('success', 'Xóa thành công')
                refetchData()
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }
    const handleExport = () => {
        const data = descriptionTemplates.map((item) => {
            return {
                name: item.name,
                description: item.description
            }
        })
        const fileData = JSON.stringify(data)
        const blob = new Blob([fileData], { type: 'text/plain' })
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.download = `description_template_${moment().format('DDMMYYYYHHmm')}.json`
        link.href = url
        link.click()
    }
    const columns: ColumnsType<IDescriptionTemplate> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 200
        },
        {
            title: 'Nội dung',
            dataIndex: 'description',
            key: 'description',
            width: 500
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'created_time',
            key: 'created_time',
            render: (text: any, record: any) => moment(text).format('DD/MM/YYYY HH:mm'),
            width: 150
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (text: any, record: any) => {
                return (
                    <Space size='large'>
                        <EditOutlined
                            style={{ color: blue.primary, fontSize: '20px' }}
                            type='button'
                            onClick={() => edit(record)}
                            title='Edit description template'
                        />
                        <DeleteOutlined
                            style={{ color: red.primary, fontSize: '20px' }}
                            type='button'
                            title='Remove description template'
                            onClick={() => onRemove(record._id)}
                        />
                    </Space>
                )
            },
            width: 150,
            fixed: 'right',
            align: 'center'
        }
    ]
    return (
        <>
            <Row justify='end' className='mb-3' gutter={[20, 20]}>
                <Space className='mr-2' size='large'>
                    <Button type='primary' onClick={refetchData} icon={<ReloadOutlined />} size='large'>
                        Refresh
                    </Button>
                    <Button type='primary' onClick={handleExport} size='large'>
                        Export
                    </Button>
                    <Button type='primary' onClick={() => toggleAddModal(true)} size='large'>
                        Thêm cấu hình
                    </Button>
                </Space>
            </Row>
            <Table
                bordered
                dataSource={descriptionTemplates}
                columns={columns}
                loading={loading}
                pagination={{
                    defaultCurrent: pageNumber,
                    current: pageNumber,
                    pageSize,
                    total,
                    onChange: handleChangePagination,
                    position: ['topLeft', 'bottomRight']
                }}
                rowKey={(record) => record?._id}
                scroll={{
                    x: 500
                }}
            />
            <DescriptionTemplateModal visible={visibleModal} toggleModal={toggleAddModal} refetch={refetchData} item={editedItem} />
        </>
    )
}

export default DescriptionTemplate
