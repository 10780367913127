import { ITableParams } from 'hooks/useTableConfigs'
import { INiche } from './INiche'
import { ITikTokShop } from './ITikTokShop'

export enum EnumProductStatus {
    LIVE = 1,
    FAILED,
    REVIEWING,
    FROZEN,
    DELETED    
}
export interface IProduct {
    _id: string
    product_id: string
    id: string
    shop_id: string
    title: string
    description: string
    status: string
    category_chains?: object[]
    brand?: object
    main_images?: object[]
    video?: object
    package_dimensions?: object
    package_weight?: object
    certifications?: object[]
    size_chart?: object
    product_attributes?: object[]
    audit_failed_reasons?: object[]
    delivery_options?: object[]
    manufacturer?: object
    recommended_categories?: object[]
    is_cod_allowed?: string
    external_product_id?: string
    product_types?: string[]
    is_not_for_sale?: string
    skus: object[]
    template_name: string
    niche: INiche
    tiktok_shop: ITikTokShop
    create_time: number
    update_time: number
    created_time?: Date
    updated_time?: Date
}

export enum EnumProductTemplateTmpStatus {
    WAITING = 1,
    PROCESSING,
    SUCCESS,
    FAILED,
    RETRY
}

export interface IProductTemplateQueryParams extends ITableParams {
    shop_id?: string
    search?: string
    status?: EnumProductTemplateTmpStatus
    tab?: boolean
}


export interface IProductQueryParams extends ITableParams {
    shop_id?: string
    search?: string
    is_template_listing?: boolean,
    status?: EnumProductStatus
    assignee_id?: string
    start_time?: number
    end_time?: number
}
