import { FC, useEffect, useState } from 'react'
import { Modal, Button, Divider, Input, Form, Select } from 'antd'
import { CloudSyncOutlined } from '@ant-design/icons'
import _ from 'lodash'
import TikTokShopAPI from 'api/TikTokShopAPI'
import { notify } from 'contexts/Notification'
import NicheAPI from 'api/NicheAPI'
const { Option } = Select
type Props = {
    visible: boolean
    toggleModal: (val: boolean) => void
    refetchData: () => void
}

const ImportKikiModal: FC<Props> = ({ visible, toggleModal, refetchData }) => {
    const [form] = Form.useForm()
    const [niches, setNiches] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
    const [subNiches, setSubNiches] = useState<any[]>([])
    const onClose = () => {
        toggleModal(false)
        setNiches([])
        form.resetFields()
    }

    useEffect(() => {
        if (visible) {
            setLoading(true)
            NicheAPI.getAllNiches()
                .then((res) => {
                    setNiches(res)
                })
                .catch((err) => {
                    notify('error', err.message)
                })
                .finally(() => setLoading(false))
        }
    }, [visible])

    const handleImportKiki = async (values: any) => {
        console.log(values)
        if (values.profile_id) {
            try {
                setLoading(true)
                await TikTokShopAPI.importFromKiki(values)
                notify('success', 'Kết nối Kiki thành công')
                onClose()
                refetchData()
            } catch (err: any) {
                notify('error', err.message)
            } finally {
                setLoading(false)
            }
        } else {
            notify('error', 'Vui lòng nhập đầy đủ thông tin')
        }
    }

    const onValuesChangeForm = (changedValues: any, allValues: any) => {
        if (changedValues.main_niche) {
            let subNiches = niches.filter(niche => niche?.parent?._id === changedValues.main_niche)
            setSubNiches(subNiches)
        }
    }
    const renderSubNiche = () => {
        return _.map(subNiches, (niche: any) => {
            return (
                <Option value={niche._id} key={niche._id}>
                    {niche?.parent?.name && niche?.parent?.name + ' - '}
                    {niche.name}
                </Option>
            )
        })
    }

    const renderMainNiches = () => {
        let mainNiches = niches.filter(niche => !niche.parent)
        return _.map(mainNiches, (niche: any) => {
            return (
                <Option value={niche._id} key={niche._id}>
                    {niche.name}
                </Option>
            )
        })
    }

    return (
        <Modal maskClosable={false} closable open={visible} onCancel={onClose} title='Kết nối tài khoản TikTok' footer={null} width={600} centered>
            <Divider />
            <Form layout={'vertical'} form={form} initialValues={{ layout: 'vertical' }} onFinish={handleImportKiki} style={{ maxWidth: 600 }} onValuesChange={onValuesChangeForm}>
                <Form.Item label={<b>Profile ID</b>} name='profile_id' rules={[{ required: true, message: 'Please input your Profile ID!' }]}>
                    <Input placeholder='Profile ID từ hệ thống KikiLogin' />
                </Form.Item>
                <Form.Item label={<b>Chọn Main Niche</b>} name='main_niche'>
                    <Select allowClear style={{ width: '100%' }} placeholder='Chọn Main Niche' size='large'>
                        {renderMainNiches()}
                    </Select>
                </Form.Item>
                <Form.Item label={<b>Chọn Sub Niche</b>} name='sub_niche_ids'>
                    <Select allowClear style={{ width: '100%' }} placeholder='Chọn Niche' size='large' mode='multiple'>
                        {renderSubNiche()}
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button size='large' htmlType='submit' icon={<CloudSyncOutlined />} type='primary' loading={loading}>
                        Kết nối từ Kiki
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ImportKikiModal
