import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AuthProvider } from 'contexts/Authenticate'
import { lazy, Suspense } from 'react'
import { Spin } from 'antd'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Login from 'pages/Auth/Login'
import Wrapper from 'pages/Wrapper'
import { AuthenticatedRoute } from 'core/RRoute'
import Register from './Auth/Register'
import { ModalProvider } from 'contexts/Modal'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            keepPreviousData: true
        }
    }
})

const App = () => (
    <BrowserRouter>
        <QueryClientProvider client={queryClient}>
           <ModalProvider>
               <AuthProvider>
                   <Suspense
                       fallback={
                           <div
                               style={{
                                   position: 'absolute',
                                   top: '50%',
                                   right: '50%'
                               }}
                           >
                               <Spin size='large' />
                           </div>
                       }
                   >
                       <Routes>
                           <Route path='/login' element={<Login />} />
                           <Route path='/register' element={<Register />} />
                           <Route element={<AuthenticatedRoute />}>
                               <Route path='*' element={<Wrapper />} />
                           </Route>
                       </Routes>
                   </Suspense>
               </AuthProvider>
           </ModalProvider>
        </QueryClientProvider>
    </BrowserRouter>
)

export default App
