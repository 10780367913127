import { Table, Row, Space, Button, Form } from 'antd'
import { useEffect, useState, FC, useCallback } from 'react'
import { EditOutlined, ReloadOutlined, DeleteOutlined } from '@ant-design/icons'
import { blue, red } from '@ant-design/colors'
import moment from 'moment'
import _ from 'lodash'
import { notify } from 'contexts/Notification'
import ConfigProductModal from './modals/ConfigProductModal'
import { IConfigProduct } from 'types/IConfigProduct'
import ConfigProductAPI from 'api/ConfigProductAPI'
import { ColumnsType } from 'antd/lib/table'
import { BRANDS } from 'const'
import { ICategory } from 'types/ICategory'
import CategoryAPI from 'api/CategoryAPI.ts'

const ConfigProduct = ({ tab }: { tab: number }) => {
    const [categories, setCategories] = useState<ICategory[]>([])
    const [configProducts, setConfigProducts] = useState<IConfigProduct[]>([])
    const [loading, setLoading] = useState(false)
    const [pageSize, setPageSize] = useState(50)
    const [pageNumber, setPageNumber] = useState(1)
    const [total, setTotal] = useState(0)
    const [form] = Form.useForm()
    const [visibleModal, setVisibleModal] = useState(false)
    const [editedItem, setEditedItem] = useState<IConfigProduct>()

    const getConfigProducts = (query: { page_size: number; page_number: number }) => {
        setLoading(true)
        ConfigProductAPI.getConfigProducts(query)
            .then((res) => {
                if (res.pagination && res.pagination.total >= 0) {
                    setTotal(res.pagination.total)
                }
                setConfigProducts(res.data)
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        CategoryAPI.getCategories().then((res) => {
            setCategories(res)
        })
    }, [])
    useEffect(() => {
        if (tab === 1) {
            refetchData()
        }
    }, [tab])

    const refetchData = () => {
        getConfigProducts({
            page_number: pageNumber,
            page_size: pageSize
        })
    }

    const handleChangePagination = (_pageNumber: number, _pageSize: number) => {
        if (pageSize !== _pageSize) {
            setPageSize(_pageSize)
            getConfigProducts({
                page_number: pageNumber,
                page_size: _pageSize
            })
        } else if (pageNumber !== _pageNumber) {
            setPageNumber(_pageNumber)
            getConfigProducts({
                page_number: _pageNumber,
                page_size: pageSize
            })
        }
    }

    const toggleAddModal = useCallback(
        (value: boolean) => {
            setVisibleModal(value)
            if (!value) {
                setEditedItem(undefined)
            }
        },
        [visibleModal]
    )

    const edit = (record: any) => {
        console.log('record', record)
        setEditedItem(record)
        toggleAddModal(true)
    }

    const onRemove = (_id: string) => {
        setLoading(true)
        ConfigProductAPI.removeConfigProduct(_id)
            .then(() => {
                notify('success', 'Xóa cấu hình thành công')
                refetchData()
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }
    const handleExport = () => {
        const data = configProducts.map((item) => {
            return {
                categories: item.categories.map((cate) => cate),
                brand_id: item.brand_id,
                package_height: item.package_height,
                package_width: item.package_width,
                package_length: item.package_length,
                product_properties: item.product_properties,
                package_weight: item.package_weight,
                quantity: item.quantity
            }
        })
        const fileData = JSON.stringify(data);
        const blob = new Blob([fileData], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = `config_product_${moment().format('DDMMYYYYHHmm')}.json`;
        link.href = url;
        link.click();
    }
    const columns: ColumnsType<IConfigProduct> = [
        {
            title: 'Category',
            dataIndex: 'categories',
            key: 'categories',
            render: (text) => {
                let categoryName = '';
                text.forEach((category: any) => {
                    const categoryInfo = categories.find((cate) => cate.id == category)
                    if (categoryInfo) {
                        categoryName += categoryInfo.name + ' / '
                    }
                })
                return categoryName
            }
        },
        {
            title: 'Brand',
            dataIndex: 'brand_id',
            key: 'brand_id',
            render: (text: any, record: any) => {
                const brandInfo = BRANDS.find((brand) => brand.id == text)
                if (brandInfo) {
                    return brandInfo.name
                }
                return 'No Brand'
            }
        },
        {
            title: 'Package Info',
            dataIndex: 'package_height',
            key: 'package_height',
            render: (text: any, record: any) => {
                return (
                    <>
                        <p><b>Height:</b> {text}</p>
                        <p><b>Width:</b> {record.package_width}</p>
                        <p><b>Length:</b> {record.package_length}</p>
                        <p><b>Unit:</b> INCH</p>
                        <p><b>Weight:</b> {record.package_weight} POUND</p>
                        <p><b>Quantity:</b> {record.quantity}</p>
                    </>
                )
            }
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'created_time',
            key: 'created_time',
            render: (text: any, record: any) => moment(text).format('DD/MM/YYYY HH:mm'),
            width: 150
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (text: any, record: any) => {
                return (
                    <Space size='large'>
                        <EditOutlined
                            style={{ color: blue.primary, fontSize: '20px' }}
                            type='button'
                            onClick={() => edit(record)}
                            title='Edit Niche'
                        />
                        <DeleteOutlined
                            style={{ color: red.primary, fontSize: '20px' }}
                            type='button'
                            title='Remove shop'
                            onClick={() => onRemove(record._id)}
                        />
                    </Space>
                )
            },
            width: 150,
            fixed: 'right',
            align: 'center'
        }
    ]
    return (
        <>
            <Row justify='end' className='mb-3' gutter={[20, 20]}>
                <Space className='mr-2' size='large'>
                    <Button type='primary' onClick={refetchData} icon={<ReloadOutlined />} size='large'>
                        Refresh
                    </Button>
                    <Button type='primary' onClick={handleExport} size='large'>
                        Export
                    </Button>
                    <Button type='primary' onClick={() => toggleAddModal(true)} size='large'>
                        Thêm cấu hình
                    </Button>
                </Space>
            </Row>
            <Form form={form} component={false}>
                <Table
                    bordered
                    dataSource={configProducts}
                    columns={columns}
                    loading={loading}
                    pagination={{
                        defaultCurrent: pageNumber,
                        current: pageNumber,
                        pageSize,
                        total,
                        onChange: handleChangePagination,
                        position: ['topLeft', 'bottomRight']
                    }}
                    rowKey={(record) => record?._id}
                    scroll={{
                        x: 500
                    }}
                />
            </Form>
            <ConfigProductModal visible={visibleModal} toggleModal={toggleAddModal} refetch={refetchData} item={editedItem} categories={categories} />
        </>
    )
}

export default ConfigProduct
