import { useAuth } from 'contexts/Authenticate'
import _ from 'lodash'
// import { useEffect, useState } from 'react'
import SideBarItem from 'core/SideBarItem'
import { filterConfigByPerms, treeRoutes } from 'core/Routes/RoutesConfig'

const SideBar = () => {
    const { user } = useAuth()
    console.log('user', user)
    const authNavItems = user.isOwner ? treeRoutes : filterConfigByPerms(
        treeRoutes,
        user?.permissions ? user?.permissions?.map((x: any) => x.code_permission) : []
    )
    const renderSideBarItems = (navItems: any[]) =>
        navItems.map((item: any, i: number) => <SideBarItem key={i} route={item.path} title={item.title} isHeader={item.isSidebarHeader} icon={item.icon} />)

    return (
        <nav id='sidebar' className='sidebar'>
            <div className='sidebar-content '>
                <a className='sidebar-brand' href='/'>
                    <i className='align-middle' data-feather='box' />
                    <span className='align-middle'>TikSuccess</span>
                </a>
                <ul className='sidebar-nav'>{renderSideBarItems(authNavItems)}</ul>
            </div>
        </nav>
    )
}

export default SideBar
