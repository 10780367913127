import {
    Card,
    Table,
    Row,
    Typography,
    Select,
    Space,
    Button,
    Image,
    Tag,
    Tabs,
    Tooltip,
    InputNumber,
    Form,
    Popconfirm,
    Upload,
    Col,
    DatePicker
} from 'antd'
import React, { useEffect, useState, useCallback, FC } from 'react'
import {
    EditOutlined,
    ReloadOutlined,
    MenuUnfoldOutlined,
    HourglassOutlined,
    CloudUploadOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    DeleteOutlined,
    FormOutlined,
    SearchOutlined,
    FieldTimeOutlined
} from '@ant-design/icons'
import { blue, red } from '@ant-design/colors'
import moment from 'moment'
import _ from 'lodash'
import { notify } from 'contexts/Notification'
import { EnumListingStatus, IListing } from 'types/IListing'
import ListingAPI from 'api/ListingAPI'
import CreateListingModal from './modals/CreateListingModal'
import TextArea from 'antd/es/input/TextArea'
import UploadAPI from 'api/UploadAPI'
import TikTokShopAPI from 'api/TikTokShopAPI'
import { ITikTokShop } from 'types/ITikTokShop'
import Search from 'antd/es/input/Search'
import EditDescriptionModal from './modals/EditDescriptionModal'
import { Link } from 'react-router-dom'
import CellProductName from 'core/Table/CellProductName'
import CheckDuplicateModal from './modals/CheckDuplicateModal'
import { Helmet } from 'react-helmet'
import ButtonProtected from 'core/ButtonProtected'
import { CODE_PERMISSION } from 'const/permission'
import { EnumAction } from 'types/IPermission'
import { useAuth } from '../../../contexts/Authenticate'
import AutoIdeaTitleGenerator from '../AutoIdea/components/AutoIdeaTitleGenerator'
import { ColumnsType } from 'antd/lib/table'
import StaffAPI from 'api/StaffAPI'
import { IUser } from 'types/IUser'
import EditPriceModal from './modals/EditPriceModal'
import { useModal } from 'contexts/Modal'
import HeaderTotalTable from 'core/Table/HeaderTotalTable'
import { useListingQuery } from 'hooks/queries/listing'
const TITLE = 'Quản lý Listing'
const { Option } = Select
const { RangePicker } = DatePicker
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean
    dataIndex: string
    title: any
    inputType: 'number' | 'text' | 'image'
    record: IListing
    index: number
    fileList: any
}
export const UNSTABLE_FEATURE_ALLOWED_EMAIL = ['mkt.htglobal@gmail.com', 'nguyenhopquang@gmail.com', 'test@gmail.com', 'tungtv@kikisoftware.io', 'htk_12@kikisoftware.io', 'htkpc22@htglobal.me', 'htkpc21@htglobal.me', 'thinhsellernv@htglobal.me']

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const [fileList, setFileList] = useState<any>([])
    const inputNode =
        inputType === 'number' ? (
            <InputNumber />
        ) : inputType === 'image' ? (
            <Upload
                beforeUpload={() => false}
                listType='picture-circle'
                maxCount={1}
                onChange={({ fileList: newFileList }) => setFileList(newFileList)}
                multiple={false}
                accept='image/*'
            >
                {fileList.length >= 1 ? null : (
                    <>
                        <CloudUploadOutlined style={{ fontSize: '40px' }} />
                    </>
                )}
            </Upload>
        ) : (
            <TextArea />
        )

    return (
        <td {...restProps}>
            {editing ? (
                inputType === 'image' ? (
                    <Form.Item name={dataIndex} style={{ margin: 0 }}>
                        {inputNode}
                    </Form.Item>
                ) : (
                    <Form.Item
                        name={dataIndex}
                        style={{ margin: 0 }}
                        rules={[
                            {
                                required: true,
                                message: `Please Input ${title}!`
                            }
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                )
            ) : (
                children
            )}
        </td>
    )
}

const ListingManagement: FC = () => {
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [pageSize, setPageSize] = useState(50)
    const [pageNumber, setPageNumber] = useState(1)
    const [selectedTab, setSelectedTab] = useState(1)
    const [visibleCheckDuplicateModal, setVisibleCheckDuplicateModal] = useState(false)
    const [visibleDescriptionModal, setVisibleDescriptionModal] = useState(false)
    const [visiblePriceModal, setVisiblePriceModal] = useState(false)
    const [editingKey, setEditingKey] = useState('')
    const [form] = Form.useForm()
    const [filterForm] = Form.useForm()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [shops, setShops] = useState<ITikTokShop[]>()
    const [selectedShop, setSelectedShop] = useState<string>('')
    const [selectedShopInfo, setSelectedShopInfo] = useState<any>()
    const [selectedItemEdit, setSelectedItemEdit] = useState<IListing>()
    const [staffs, setStaffs] = useState<IUser[]>([])
    const { user } = useAuth()
    const formWatch = Form.useWatch([], filterForm)
    const { openModal } = useModal()
    const listingQueryResponse = useListingQuery({
        page_size: pageSize,
        page_number: pageNumber,
        search,
        ...formWatch,
        status: Number(selectedTab) !== -1 ? Number(selectedTab) : undefined,
        start_time:
            filterForm.getFieldValue('created_time') && filterForm.getFieldValue('created_time')[0]
                ? filterForm.getFieldValue('created_time')[0].valueOf()
                : undefined,
        end_time:
            filterForm.getFieldValue('created_time') && filterForm.getFieldValue('created_time')[1]
                ? filterForm.getFieldValue('created_time')[1].valueOf()
                : undefined
    })

    useEffect(() => {
        setLoading(true)
        TikTokShopAPI.getTikTokShops({
            page_size: 100,
            page_number: 1
        })
            .then((res) => {
                setShops(res)
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
        StaffAPI.getAllStaffs()
            .then((res) => {
                setStaffs(res)
            })
            .catch((err) => {
                notify('error', err.message)
            })
    }, [])

    const refetchData = () => {
        listingQueryResponse?.refetch()
    }

    const handleChangePagination = (_pageNumber: number, _pageSize: number) => {
        if (pageSize !== _pageSize) {
            setPageSize(_pageSize)
        } else if (pageNumber !== _pageNumber) {
            setPageNumber(_pageNumber)
        }
    }
    const onChangeTab = (key: any) => {
        setSelectedTab(Number(key))
        setPageNumber(1)
    }
    const openAddModal = () => {
        openModal(<CreateListingModal refetch={refetchData} shop_id={selectedShop as string} />)
    }

    const toggleEditModal = useCallback(
        (value: boolean) => {
            setVisibleDescriptionModal(value)
            if (!value) {
                setSelectedItemEdit(undefined)
            }
        },
        [visibleDescriptionModal, selectedItemEdit]
    )
    const toggleCheckDuplicateModal = useCallback(
        (value: boolean) => {
            setVisibleCheckDuplicateModal(value)
            if (!value) {
                setSelectedItemEdit(undefined)
            }
        },
        [visibleCheckDuplicateModal, selectedItemEdit]
    )
    const toggleEditPriceModal = useCallback(
        (value: boolean) => {
            setVisiblePriceModal(value)
            if (!value) {
                setSelectedItemEdit(undefined)
            }
        },
        [visiblePriceModal, selectedItemEdit]
    )
    const isEditing = (record: IListing) => record._id === editingKey

    const edit = (record: IListing) => {
        form.setFieldsValue({ product_name: record.product_name })
        setEditingKey(record._id)
    }

    const onRemove = (_id: string) => {
        setLoading(true)
        ListingAPI.removeListing(_id)
            .then(() => {
                notify('success', 'Xóa listing thành công!')
                refetchData()
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }
    const cancel = () => {
        setEditingKey('')
    }
    const save = async (_id: string) => {
        try {
            const row = (await form.validateFields()) as IListing
            console.log('newData', row)
            setLoading(true)
            const diff: any = {
                product_name: row.product_name
            }
            if (row?.images && row?.images?.fileList && row?.images?.fileList[0]) {
                try {
                    const resImage = await UploadAPI.uploadImage(row?.images?.fileList[0].originFileObj)
                    diff.main_image = resImage
                    ListingAPI.editListing(_id, diff)
                        .then((res) => {
                            notify('success', 'Cập nhật thành công')
                            refetchData()
                            setEditingKey('')
                        })
                        .catch((err) => {
                            notify('error', err.message)
                        })
                        .finally(() => setLoading(false))
                } catch (err: any) {
                    notify('error', err.message)
                } finally {
                    setLoading(false)
                }
            } else {
                ListingAPI.editListing(_id, diff)
                    .then((res) => {
                        notify('success', 'Cập nhật thành công')
                        refetchData()
                        setEditingKey('')
                    })
                    .catch((err) => {
                        notify('error', err.message)
                    })
                    .finally(() => setLoading(false))
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo)
        }
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange
    }
    const onPublishMulti = () => {
        ListingAPI.publishMulti({ listing_ids: selectedRowKeys as string[] })
            .then(() => {
                notify('success', 'Publish listing thành công!')
                setSelectedRowKeys([])
                refetchData()
            })
            .catch((err) => {
                notify('error', err.message)
            })
    }

    const onRemoveMulti = () => {
        ListingAPI.removeMultiListing({ listing_ids: selectedRowKeys as string[] })
            .then(() => {
                notify('success', 'Xóa thành công!')
                setSelectedRowKeys([])
                refetchData()
            })
            .catch((err) => {
                notify('error', err.message)
            })
    }
    const onPublishListing = (_id: string) => {
        ListingAPI.publishListing(_id)
            .then(() => {
                notify('success', 'Publish listing thành công!')
                refetchData()
            })
            .catch((err) => {
                notify('error', err.message)
            })
    }

    const onCancelListing = (_id: string) => {
        ListingAPI.cancelListing(_id)
            .then(() => {
                notify('success', 'Hủy listing thành công!')
                refetchData()
            })
            .catch((err) => {
                notify('error', err.message)
            })
    }

    const onCloneListing = (_id: string) => {
        ListingAPI.cloneListing(_id)
            .then(() => {
                notify('success', 'Clone listing thành công!')
                refetchData()
            })
            .catch((err) => {
                notify('error', err.message)
            })
    }

    const onEditDescription = (record: IListing) => {
        setSelectedItemEdit(record)
        toggleEditModal(true)
    }

    const onEditPrice = (record: IListing) => {
        setSelectedItemEdit(record)
        toggleEditPriceModal(true)
    }
    const renderListShops = () => {
        return _.map(shops, (shop: any) => {
            return (
                <Option value={shop._id} key={shop._id} textSearch={`${shop.profile_name} ${shop.name}`}>
                    <Tag color='#1677ff'>{shop.profile_name ? `${shop.profile_name}` : ''}</Tag>
                    {shop.name}
                </Option>
            )
        })
    }
    const onSearch = (value: string) => {
        setSearch(value)
        setPageNumber(1)
    }

    const onCheckDuplicate = (item: IListing) => {
        setSelectedItemEdit(item)
        toggleCheckDuplicateModal(true)
    }
    const onSyncKikiAgain = (_id: string) => {
        setLoading(true)
        TikTokShopAPI.syncKikiProfile(_id)
            .then(() => {
                notify('success', 'Đồng bộ dữ liệu thành công!')
                refetchData()
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }
    const renderStaffs = () => {
        return _.map(staffs, (staff: any) => {
            return (
                <Option value={staff._id} key={staff._id}>
                    {staff.full_name}
                </Option>
            )
        })
    }
    const onChangeValues = (values: any) => {
        console.log('onChangeValues', values)
        setPageNumber(1)
        if (_.has(values, 'shop_id')) {
            if (values.shop_id) {
                const shop = shops?.find((shop) => shop._id === values.shop_id)
                setSelectedShopInfo(shop)
                setSelectedShop(values.shop_id)
            } else {
                setSelectedShopInfo(undefined)
                setSelectedShop('')
            }
        }
        if (_.has(values, 'created_time')) {
            if (values?.created_time && values?.created_time[0] && values?.created_time[1]) {
                values.created_time[1] = values.created_time[1].endOf('day')
                form.setFieldValue('created_time', values.created_time)
            }
        }
    }
    const columns: any = [
        {
            title: 'Ảnh mẫu',
            dataIndex: 'images',
            key: 'images',
            width: 230,
            render: (text: any, record: any, index: number) => {
                if (record.status === EnumListingStatus.DRAFT) {
                    if (text && text[0].id) {
                        return (
                            <Link to={text[0]?.url} target='_blank'>
                                <Image src={text[0]?.url} width={200} preview={false} />
                            </Link>
                        )
                    } else {
                        return (
                            <Upload beforeUpload={() => false} listType='picture-circle' multiple={false} disabled>
                                <CloudUploadOutlined style={{ fontSize: '40px' }} />
                            </Upload>
                        )
                    }
                } else {
                    if (text[0].url_list && _.isArray(text[0].url_list)) {
                        return (
                            <Link to={text[0]?.url_list[0]} target='_blank'>
                                <Image src={text[0]?.url_list[0]} width={200} preview={false} />
                            </Link>
                        )
                    } else {
                        return (
                            <Link to={text[0]?.url} target='_blank'>
                                <Image src={text[0]?.url} width={200} preview={false} />
                            </Link>
                        )
                    }
                }
            },
            editable: true,
            align: 'center'
        },
        {
            title: 'Tên sản phẩm',
            dataIndex: 'product_name',
            key: 'product_name',
            render: (text: string, record: any) => {
                return (
                    <CellProductName
                        product_id={record.product_id}
                        product_name={text}
                        skus={record.skus}
                        tiktok_shop={record.tiktok_shop}
                        is_clone={record.is_clone}
                    />
                )
            },
            width: 300,
            editable: true
        },
        {
            title: 'Mô tả',
            dataIndex: 'description',
            key: 'description',
            render: (text: any, record: any) => {
                if (record.status === EnumListingStatus.DRAFT)
                    return (
                        <EditOutlined
                            style={{ color: blue.primary, fontSize: '20px' }}
                            type='button'
                            onClick={() => onEditDescription(record)}
                            title='Edit description Listing'
                        />
                    )
                return null
            },
            width: 130,
            align: 'center'
        },
        {
            title: 'Keyword',
            dataIndex: 'keyword',
            key: 'keyword',
            width: 100,
            align: 'center'
        },
        {
            title: 'Số lượng',
            dataIndex: 'skus',
            key: 'skus',
            width: 100,
            render: (text: any, record: any, index: number) => {
                return text[0]?.quantities && <span>{text[0]?.quantities[0].available_quantity}</span>
            },
            align: 'center'
        },
        {
            title: 'Giá bán lẻ',
            dataIndex: 'skus',
            key: 'skus',
            render: (text: any, record: any, index: number) => {
                return (
                    text[0]?.base_price && (
                        <span>
                            {text[0]?.base_price.sale_price}
                            <b>$</b>
                            {record.status === EnumListingStatus.DRAFT && (
                                <EditOutlined
                                    style={{ color: blue.primary, fontSize: '20px', marginLeft: '5px' }}
                                    type='button'
                                    onClick={() => onEditPrice(record)}
                                    title='Edit Price Listing'
                                />
                            )}
                        </span>
                    )
                )
            },
            width: 120,
            align: 'center'
        },
        {
            title: 'Product Template',
            dataIndex: 'product_template',
            key: 'product_template',
            render: (text: any, record: any, index: number) => {
                if (text) {
                    return (
                        <>
                            <p>
                                <b>Name: </b>
                                {text.template_name}
                            </p>

                            <p>
                                <i>
                                    <b style={{ color: '#000' }}>ID:</b> {text.product_id}
                                </i>
                            </p>
                        </>
                    )
                }
                return
            },
            width: 200
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (text: any, record: any) => {
                if (text === EnumListingStatus.DRAFT) {
                    return <Tag color='#434343'>DRAFT</Tag>
                } else if (text === EnumListingStatus.WAITING) {
                    return <Tag color='#fadb14'>WAITING</Tag>
                } else if (text === EnumListingStatus.PROCESSING) {
                    return <Tag color='#1677ff'>PROCESSING</Tag>
                } else if (text === EnumListingStatus.SUCCESS) {
                    return <Tag color='#52c41a'>SUCCESS</Tag>
                } else if (text === EnumListingStatus.RETRY) {
                    return (
                        <>
                            <Tag color='#ad6800'>WAIT RETRY</Tag>
                            <br />
                            <p style={{ color: 'red' }}>
                                <i>
                                    <b>Số lần thử lại:</b> {record.retry_counter || 0}
                                </i>
                            </p>
                        </>
                    )
                } else {
                    return (
                        <>
                            <Tag color='#f5222d'>FAILED</Tag>
                            <br />
                            <Tooltip placement='top' title={record.note}>
                                <p style={{ color: 'red' }}>
                                    <i>{record.note && record.note.length > 255 ? record.note.substring(0, 255) + '...' : record.note}</i>
                                </p>
                            </Tooltip>
                        </>
                    )
                }
            },
            width: 120,
            align: 'center'
        },
        {
            title: 'Proxy/Cookie',
            dataIndex: 'tiktok_shop',
            key: 'tiktok_shop',
            render: (text: any) => {
                return (
                    <>
                        <p style={{ color: 'rgba(0,0,0,.55)' }} className='mb-2'>
                            <b style={{ color: '#000' }}>Cookie: </b>{' '}
                            {text?.cookie_status === 'LIVE' ? (
                                <Tag color='#389e0d'>{text?.cookie_status}</Tag>
                            ) : (
                                <Tag color='#cf1322'>{text?.cookie_status}</Tag>
                            )}
                        </p>
                        <p style={{ color: 'rgba(0,0,0,.55)' }} className='mb-4'>
                            <b style={{ color: '#000' }}>Proxy: </b>{' '}
                            {text?.proxy_status === 'LIVE' ? (
                                <Tag color='#389e0d'>{text?.proxy_status}</Tag>
                            ) : (
                                <Tag color='#cf1322'>{text?.proxy_status}</Tag>
                            )}
                        </p>
                        <Popconfirm
                            placement='topLeft'
                            title={'Bạn muốn đồng bộ lại dữ liệu từ Kiki?'}
                            okText='Yes'
                            cancelText='No'
                            onConfirm={() => onSyncKikiAgain(text._id)}
                            onCancel={() => {}}
                        >
                            <Button type='primary'>Đồng bộ KIKI</Button>
                        </Popconfirm>
                    </>
                )
            },
            width: 150,
            align: 'center'
        },
        {
            title: 'Ngày đăng',
            dataIndex: 'created_time',
            key: 'created_time',
            render: (text: any, record: any) => moment(text).format('DD/MM/YYYY HH:mm'),
            width: 130,
            align: 'center'
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (text: any, record: any) => {
                const editable = isEditing(record)
                return editable ? (
                    <span>
                        <Typography.Link onClick={() => save(record._id)} style={{ marginInlineEnd: 15 }}>
                            <span style={{ color: 'blue', fontSize: '17px' }}>
                                <b>Save</b>
                            </span>
                        </Typography.Link>
                        <Typography.Link title='Sure to cancel?' onClick={cancel}>
                            <span style={{ color: 'red', cursor: 'pointer', fontSize: '17px' }}>
                                <b>Cancel</b>
                            </span>
                        </Typography.Link>
                    </span>
                ) : record.status === EnumListingStatus.DRAFT ? (
                    <>
                        <div className='mb-3'>
                            <Space size='large'>
                                <SearchOutlined
                                    style={{ color: blue.primary, fontSize: '20px' }}
                                    type='button'
                                    onClick={() => onCheckDuplicate(record)}
                                    title='Check duplicate'
                                />
                                <EditOutlined
                                    style={{ color: blue.primary, fontSize: '20px' }}
                                    type='button'
                                    onClick={() => edit(record)}
                                    title='Edit Listing'
                                />
                                <DeleteOutlined
                                    style={{ color: red.primary, fontSize: '20px' }}
                                    type='button'
                                    title='Remove Listing'
                                    onClick={() => onRemove(record._id)}
                                />
                            </Space>
                        </div>
                        <Space size='large'>
                            <Button type='primary' onClick={() => onPublishListing(record._id)}>
                                Đăng sản phẩm
                            </Button>
                        </Space>
                        {user && UNSTABLE_FEATURE_ALLOWED_EMAIL.includes(user.email) && (
                            <Space size='large' className='mt-3'>
                                <Button type='primary' onClick={() => onCloneListing(record._id)}>
                                    Redesign listing
                                </Button>
                            </Space>
                        )}
                    </>
                ) : record.status === EnumListingStatus.FAILED ? (
                    <>
                        <div className='mb-3'>
                            <Space size='large'>
                                <SearchOutlined
                                    style={{ color: blue.primary, fontSize: '20px' }}
                                    type='button'
                                    onClick={() => onCheckDuplicate(record)}
                                    title='Check duplicate'
                                />
                                <EditOutlined
                                    style={{ color: blue.primary, fontSize: '20px' }}
                                    type='button'
                                    onClick={() => edit(record)}
                                    title='Edit Listing'
                                />
                                <DeleteOutlined
                                    style={{ color: red.primary, fontSize: '20px' }}
                                    type='button'
                                    title='Remove Listing'
                                    onClick={() => onRemove(record._id)}
                                />
                            </Space>
                        </div>
                        <Space size='large'>
                            <Button type='primary' onClick={() => onPublishListing(record._id)}>
                                Đăng lại
                            </Button>
                        </Space>
                    </>
                ) : record.status === EnumListingStatus.WAITING ? (
                    <>
                        <Space size='large'>
                            <Button type='primary' danger onClick={() => onCancelListing(record._id)}>
                                Hủy
                            </Button>
                        </Space>
                    </>
                ) : record.status === EnumListingStatus.SUCCESS ? (
                    <>
                        {user && UNSTABLE_FEATURE_ALLOWED_EMAIL.includes(user.email) && (
                            <Space size='large' className='mt-3'>
                                <Button type='primary' onClick={() => onCloneListing(record._id)}>
                                    Redesign listing
                                </Button>
                            </Space>
                        )}
                    </>
                ) : null
            },

            width: 150,
            fixed: 'right',
            align: 'center'
        }
    ]

    const mergedColumns: any = columns.map((col: any) => {
        if (!col.editable) {
            return col
        }
        return {
            ...col,
            onCell: (record: IListing) => ({
                record,
                inputType: col.dataIndex === 'age' ? 'number' : col.dataIndex === 'images' ? 'image' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record)
            })
        }
    })

    const LIST_TABS = [
        {
            id: 1,
            label: 'Listing nháp',
            icon: <FormOutlined />
        },
        {
            id: 2,
            label: 'Đang chờ',
            icon: <HourglassOutlined />
        },
        {
            id: 3,
            label: 'Đang xử lý',
            icon: <CloudUploadOutlined />
        },
        {
            id: 4,
            label: 'Thành công',
            icon: <CheckCircleOutlined />
        },
        {
            id: 5,
            label: 'Thất bại',
            icon: <CloseCircleOutlined />
        },
        {
            id: 6,
            label: 'Chờ thử lại',
            icon: <FieldTimeOutlined />
        },
        {
            id: -1,
            label: 'Tất cả',
            icon: <MenuUnfoldOutlined />
        }
    ]
    return (
        <Card
            title={
                <span style={{ fontSize: '20px' }}>
                    Quản lý Listing{' '}
                    {selectedShopInfo ? (
                        <span style={{ color: 'blue' }}>
                            <Tag color='#1677ff'>{selectedShopInfo?.profile_name ? `${selectedShopInfo?.profile_name}` : ''}</Tag>
                            {selectedShopInfo?.name}
                        </span>
                    ) : null}
                </span>
            }
        >
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Form form={filterForm} onValuesChange={onChangeValues}>
                <Row className='mb-3' gutter={[20, 20]}>
                    <Col span={12}>
                        <Row gutter={[20, 20]} className='mb-4'>
                            <Col span={14}>
                                <Search
                                    placeholder='Tìm kiếm theo: Tên sản phẩm, ID, Profile, Template Name'
                                    allowClear
                                    enterButton='Search'
                                    onSearch={_.debounce(onSearch, 250)}
                                    size='large'
                                />
                            </Col>
                        </Row>
                        <Row gutter={[20, 20]}>
                            <Col span={8}>
                                <Form.Item name='shop_id'>
                                    <Select
                                        showSearch
                                        optionFilterProp='textSearch'
                                        style={{ width: '100%' }}
                                        placeholder='Tất cả cửa hàng'
                                        allowClear
                                        size='large'
                                    >
                                        <Option value=''>Tất cả cửa hàng</Option>
                                        {renderListShops()}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name='assignee_id'>
                                    <Select
                                        allowClear
                                        showSearch
                                        optionFilterProp='textSearch'
                                        style={{ width: '100%' }}
                                        placeholder='Tất cả nhân viên'
                                        size='large'
                                    >
                                        <Option value=''>Tất cả nhân viên</Option>
                                        {renderStaffs()}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name='created_time'>
                                    <RangePicker size='large' format='DD-MM-YYYY' />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                        <Space size='large' className='mr-2'>
                            {selectedRowKeys.length > 0 && (
                                <>
                                    <ButtonProtected permission={{ [CODE_PERMISSION.LISTING_MANAGEMENT]: [EnumAction.Create] }}>
                                        <Popconfirm
                                            placement='topLeft'
                                            title={'Bạn chắc chắn xuất bản Listing đã chọn?'}
                                            okText='Yes'
                                            cancelText='No'
                                            onConfirm={onPublishMulti}
                                            onCancel={() => {}}
                                        >
                                            <Button type='primary' size='large'>
                                                Đăng SP
                                            </Button>
                                        </Popconfirm>
                                    </ButtonProtected>
                                    <ButtonProtected permission={{ [CODE_PERMISSION.LISTING_MANAGEMENT]: [EnumAction.Delete] }}>
                                        <Popconfirm
                                            placement='topLeft'
                                            title={'Bạn chắc chắn xóa Listing đã chọn?'}
                                            okText='Yes'
                                            cancelText='No'
                                            onConfirm={onRemoveMulti}
                                            onCancel={() => {}}
                                        >
                                            <Button type='primary' danger size='large'>
                                                Xóa
                                            </Button>
                                        </Popconfirm>
                                    </ButtonProtected>
                                </>
                            )}
                            <ButtonProtected permission={{ [CODE_PERMISSION.LISTING_MANAGEMENT]: [EnumAction.Create] }}>
                                <Button type='primary' onClick={openAddModal} disabled={!selectedShop} size='large'>
                                    Tạo mới Listing
                                </Button>
                            </ButtonProtected>
                            <Button type='primary' onClick={refetchData} icon={<ReloadOutlined />} size='large'>
                                Refresh
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>

            <Tabs
                activeKey={selectedTab.toString()}
                defaultActiveKey='1'
                type='card'
                items={LIST_TABS.map((tab, i) => {
                    return {
                        key: tab.id.toString(),
                        label: tab.label,
                        children: (
                            <Form form={form} component={false}>
                                <Table
                                    bordered
                                    dataSource={listingQueryResponse?.data?.data ?? []}
                                    columns={mergedColumns}
                                    loading={listingQueryResponse?.isFetching || loading}
                                    pagination={{
                                        defaultCurrent: pageNumber,
                                        current: pageNumber,
                                        pageSize,
                                        total: listingQueryResponse.data?.pagination?.total ?? 0,
                                        onChange: handleChangePagination,
                                        position: ['topLeft', 'bottomRight'],
                                        showTotal: (total) => <HeaderTotalTable total={total} text='Tổng số listings:' />
                                    }}
                                    rowKey={(record) => record?._id}
                                    scroll={{
                                        x: 500
                                    }}
                                    components={{
                                        body: {
                                            cell: EditableCell
                                        }
                                    }}
                                    rowSelection={rowSelection}
                                />
                            </Form>
                        ),
                        icon: tab.icon
                    }
                })}
                onChange={onChangeTab}
            />
            <EditDescriptionModal visible={visibleDescriptionModal} toggleModal={toggleEditModal} refetch={refetchData} item={selectedItemEdit} />
            <EditPriceModal visible={visiblePriceModal} toggleModal={toggleEditPriceModal} refetch={refetchData} item={selectedItemEdit} />
            <CheckDuplicateModal visible={visibleCheckDuplicateModal} toggleModal={toggleCheckDuplicateModal} item={selectedItemEdit} />
        </Card>
    )
}

export default ListingManagement
