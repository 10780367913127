/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { flatStructure } from 'utils/data-structure-utils'
import { flattenDeep } from 'lodash-es'
import { Badge } from 'antd'
import _ from 'lodash'

const SideBarItem = ({ route, title, isHeader, icon }: { route: string; title: string; isHeader: boolean; icon?: string }) => {
    const location = useLocation()
    const activeRoute = (...routeName: string[]) => (isActive(...routeName) ? 'active' : '')

    const isActive = (...routes: string[]) => {
        for (const route of routes) {
            if (!route || route === '#') {
                continue
            }

            const { pathname } = location
            if (pathname === route) {
                return true
            }
        }

        return false
    }

    const parseRoute = (route: string) => route

    return isHeader ? (
        <li className='sidebar-header'>{title}</li>
    ) : (
        <li className={`sidebar-item ${activeRoute(route)}`}>
            <Link to={parseRoute(route) || '#'} replace className='sidebar-link'>
                <i className={`align-middle fas fa-fw fa-${icon}`} data-feather={icon} />
                <span className='align-middle'>{title}</span>
            </Link>
        </li>
    )
}

export default SideBarItem
