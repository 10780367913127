import { Card, Table, Row, Col, Select, Space, Button, Tag, Switch, Tabs, Form, Dropdown, message } from 'antd'
import { useEffect, useState, useCallback, FC } from 'react'
import { DownOutlined, ReloadOutlined, DeleteOutlined } from '@ant-design/icons'
import { blue, red } from '@ant-design/colors'
import moment from 'moment'
import _ from 'lodash'
import { notify } from 'contexts/Notification'
import Search from 'antd/es/input/Search'
import { Helmet } from 'react-helmet'
import { useAllStaffQuery } from 'hooks/queries/staff'
import { ColumnsType } from 'antd/es/table'
import { useAllTikTokShopQuery } from 'hooks/queries/tiktok-shop'
import { usePromotionQuery } from 'hooks/queries/promotion'
import { IPromotion } from 'types/IPromotion'
import SyncPromotionModal from './modals/SyncPromotionModal'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import PromotionModal from './modals/PromotionModal'
import PromotionAPI from 'api/PromotionAPI'
import type { MenuProps } from 'antd'

const { Option } = Select
const TITLE = 'Quản lý Promotion'

const PromotionManagement: FC = () => {
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [pageSize, setPageSize] = useState(50)
    const [pageNumber, setPageNumber] = useState(1)
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleSyncModal, setVisibleSyncModal] = useState(false)
    const [selectedTab, setSelectedTab] = useState(1)
    const [selectedPromotion, setSelectedPromotion] = useState<IPromotion>()
    const [form] = Form.useForm()
    const staffsQueryResponse = useAllStaffQuery()
    const shopsQueryResponse = useAllTikTokShopQuery()
    const promotionQueryResponse = usePromotionQuery({
        page_size: pageSize,
        page_number: pageNumber,
        search,
        ...form.getFieldsValue(),
        status: Number(selectedTab)
    })

    const refetchData = () => {
        promotionQueryResponse.refetch()
    }

    const handleChangePagination = (_pageNumber: number, _pageSize: number) => {
        if (pageSize !== _pageSize) {
            setPageSize(_pageSize)
        } else if (pageNumber !== _pageNumber) {
            setPageNumber(_pageNumber)
        }
    }

    const toggleModal = useCallback(
        (value: boolean) => {
            setVisibleModal(value)
            if (!value) {
                setSelectedPromotion(undefined)
            }
        },
        [visibleModal, selectedPromotion]
    )

    const toggleSyncModal = useCallback(
        (value: boolean) => {
            setVisibleSyncModal(value)
        },
        [visibleSyncModal]
    )

    const renderListShops = () => {
        return _.map(shopsQueryResponse?.data, (shop: any) => {
            return (
                <Option value={shop._id} key={shop._id} textSearch={`${shop.profile_name} ${shop.name}`}>
                    <Tag color='#1677ff'>{shop.profile_name ? `${shop.profile_name}` : ''}</Tag>
                    {shop.name}
                </Option>
            )
        })
    }
    const renderStaffs = () => {
        return _.map(staffsQueryResponse?.data, (staff: any) => {
            return (
                <Option value={staff._id} key={staff._id}>
                    {staff.full_name}
                </Option>
            )
        })
    }
    const onEditPromotion = (promotion: IPromotion) => {
        setSelectedPromotion(promotion)
        toggleModal(true)
    }

    const onSearch = (value: string) => {
        setSearch(value)
        setPageNumber(1)
    }
    const onChangeValues = (values: any) => {
        setPageNumber(1)
        promotionQueryResponse?.refetch({
            page_size: pageSize,
            page_number: 1,
            search,
            ...form.getFieldsValue()
        })
    }

    const handleDeactivate = (_id: string) => {
        notify('success', 'Deactivate promotion success')
        setLoading(true)
        PromotionAPI.deactivatePromotion(_id)
            .then((res) => {
                notify('success', 'Deactivate promotion success')
                refetchData()
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }

    const onCopy = (value: string) => {
        message.success(`Đã Copied: ${value}`)
    }

    const handleMenuClick = (e: any, record: IPromotion) => {
        if (e.key === '1') {
            onEditPromotion(record)
        } else if (e.key === '2') {
        }
    }

    const renderMenuItem = (record: IPromotion) => {
        const ListMenus = [
            {
                label: 'Chỉnh sửa',
                key: '1',
                isShown: ['ONGOING', 'NOT_START'].includes(record.status)
            },
            {
                label: 'Duplicate',
                key: '2',
                isShown: true
            },
            {
                label: 'Lưu file mẫu',
                key: '3',
                isShown: true
            }
        ]
        return ListMenus.filter((item) => item.isShown).map((item) => _.pick(item, ['label', 'key']))
    }
    const columns: ColumnsType<IPromotion> = [
        {
            title: 'Promotion name',
            dataIndex: 'title',
            key: 'title',
            width: 300
        },
        {
            title: 'Thông tin cửa hàng',
            dataIndex: 'tiktok_shop',
            key: 'tiktok_shop',
            width: 250,
            align: 'left',
            render: (tiktok_shop, record) => {
                return (
                    <>
                        <CopyToClipboard text={tiktok_shop?.name} onCopy={() => onCopy(tiktok_shop?.name)}>
                            <p style={{ color: 'rgba(0,0,0,.55)' }} className='mb-2 clickable'>
                                <b style={{ color: '#000' }}>Shop: </b>
                                <Tag color='#1677ff'>{tiktok_shop?.name}</Tag>
                            </p>
                        </CopyToClipboard>
                        <CopyToClipboard text={tiktok_shop?.profile_name} onCopy={() => onCopy(tiktok_shop?.profile_name)}>
                            <p style={{ color: 'rgba(0,0,0,.55)' }} className='mb-2 clickable'>
                                <b style={{ color: '#000' }}>Profile: </b>
                                <Tag color='#1677ff'>{tiktok_shop?.profile_name}</Tag>
                            </p>
                        </CopyToClipboard>
                        {tiktok_shop?.assignee && (
                            <p style={{ color: 'rgba(0,0,0,.55)' }} className='mt-2'>
                                <b style={{ color: '#000' }}>Nhân viên: </b> <Tag color='#d46b08'>{tiktok_shop?.assignee.full_name}</Tag>
                            </p>
                        )}
                    </>
                )
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            align: 'center',
            render: (text) => {
                let color = blue[5]
                if (text === 'DEACTIVATED') {
                    color = '#e14653'
                } else if (text === 'ONGOING') {
                    color = '#369afe'
                } else if (text === 'EXPIRED') {
                    color = '#8c8c8c'
                } else if (text === 'NOT_START') {
                    color = '#369afe'
                }
                return <Tag color={color}>{text === 'NOT_START' ? 'UPCOMING' : text}</Tag>
            }
        },
        {
            title: 'Ngày bắt đầu',
            dataIndex: 'begin_time',
            key: 'begin_time',
            render: (text) => text && moment.unix(text).format('DD/MM/YYYY HH:mm'),
            width: 150,
            align: 'center'
        },
        {
            title: 'Ngày kết thúc',
            dataIndex: 'end_time',
            key: 'end_time',
            render: (text) => text && moment.unix(text).format('DD/MM/YYYY HH:mm'),
            width: 150,
            align: 'center'
        },
        {
            title: 'Type',
            dataIndex: 'activity_type',
            key: 'activity_type',
            width: 120,
            align: 'center'
        },
        {
            title: 'Bật/Tắt',
            dataIndex: 'status',
            key: 'status',
            width: 120,
            align: 'center',
            render: (text, record) => {
                return (
                    <Switch
                        checked={text !== 'DEACTIVATED'}
                        disabled={['DEACTIVATED', 'EXPIRED'].includes(text) || loading}
                        onClick={() => handleDeactivate(record._id)}
                    />
                )
            }
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (text: any, record: any) => {
                return (
                    <Dropdown.Button
                        icon={<DownOutlined />}
                        menu={{
                            items: renderMenuItem(record),
                            onClick: (e) => handleMenuClick(e, record)
                        }}
                        trigger={['click']}
                    >
                        Actions
                    </Dropdown.Button>
                )
            },
            width: 150,
            fixed: 'right',
            align: 'center'
        }
    ]

    return (
        <Card title='Danh sách promotions'>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Form form={form} onValuesChange={onChangeValues}>
                <Row justify='end' className='mb-3' gutter={[20, 20]}>
                    <Col span={16}>
                        <Row gutter={[20, 20]} className='mb-4'>
                            <Col span={14}>
                                <Search
                                    placeholder='Tìm kiếm theo: Tên, ID, Profile, Template Name'
                                    allowClear
                                    enterButton='Search'
                                    onSearch={_.debounce(onSearch, 250)}
                                    size='large'
                                />
                            </Col>
                        </Row>
                        <Row gutter={[20, 20]}>
                            <Col span={12}>
                                <Form.Item name='shop_id'>
                                    <Select
                                        allowClear
                                        showSearch
                                        optionFilterProp='textSearch'
                                        style={{ width: '100%' }}
                                        placeholder='Tất cả cửa hàng'
                                        size='large'
                                    >
                                        <Option value=''>Tất cả cửa hàng</Option>
                                        {renderListShops()}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name='assignee_id'>
                                    <Select
                                        allowClear
                                        showSearch
                                        optionFilterProp='textSearch'
                                        style={{ width: '100%' }}
                                        placeholder='Tất cả nhân viên'
                                        size='large'
                                    >
                                        <Option value=''>Tất cả nhân viên</Option>
                                        {renderStaffs()}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                        <Space className='mr-2' size='large'>
                            <Button type='primary' onClick={() => toggleModal(true)} size='large'>
                                Tạo Promotion
                            </Button>
                            <Button type='primary' onClick={() => toggleSyncModal(true)} size='large'>
                                Đồng bộ Promotion
                            </Button>
                            <Button type='primary' onClick={refetchData} icon={<ReloadOutlined />} size='large'>
                                Refresh
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>

            <Table
                bordered
                dataSource={promotionQueryResponse?.data?.data ?? []}
                columns={columns}
                loading={promotionQueryResponse?.isFetching || loading}
                pagination={{
                    defaultCurrent: pageNumber,
                    current: pageNumber,
                    pageSize,
                    total: promotionQueryResponse?.data?.pagination?.total,
                    onChange: handleChangePagination,
                    position: ['topLeft', 'bottomRight'],
                    showTotal: (total) => (
                        <span>
                            Tổng số promotions: <strong>{total}</strong>
                        </span>
                    )
                }}
                rowKey={(record) => record?._id}
                scroll={{
                    x: 500
                }}
            />
            <SyncPromotionModal visible={visibleSyncModal} toggleModal={toggleSyncModal} />
            <PromotionModal visible={visibleModal} toggleModal={toggleModal} refetch={refetchData} item={selectedPromotion} />
        </Card>
    )
}

export default PromotionManagement
