import { Card, Table, Row, Col, Select, Space, Button, Image, Tag, Popconfirm, Tabs, Form, DatePicker } from 'antd'
import { useEffect, useState, useCallback, FC } from 'react'
import {
    EditOutlined,
    ReloadOutlined,
    StopOutlined,
    DeleteOutlined,
    HourglassOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    MenuUnfoldOutlined
} from '@ant-design/icons'
import { blue, red } from '@ant-design/colors'
import moment from 'moment'
import _ from 'lodash'
import SyncProductModal from './modals/SyncProductModal'
import { notify } from 'contexts/Notification'
import { EnumProductStatus, IProduct } from 'types/IProduct'
import ProductAPI from 'api/ProductAPI'
import Search from 'antd/es/input/Search'
import { Link } from 'react-router-dom'
import CellProductName from 'core/Table/CellProductName'
import EditProductModal from './modals/EditProductModal'
import { Helmet } from 'react-helmet'
import { useAllStaffQuery } from 'hooks/queries/staff'
import { ColumnsType } from 'antd/es/table'
import { useProductQuery } from 'hooks/queries/product'
import { useAllTikTokShopQuery } from 'hooks/queries/tiktok-shop'
import HeaderTotalTable from 'core/Table/HeaderTotalTable'
const { Option } = Select
const { RangePicker } = DatePicker
const TITLE = 'Quản lý Sản phẩm'
const ProductManagement: FC = () => {
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [pageSize, setPageSize] = useState(50)
    const [pageNumber, setPageNumber] = useState(1)
    const [visibleAddModal, setVisibleAddModal] = useState(false)
    const [visibleEditModal, setVisibleEditModal] = useState(false)
    const [selectedTab, setSelectedTab] = useState(1)
    const [selectedProduct, setSelectedProduct] = useState<IProduct>()
    const [form] = Form.useForm()
    const formWatch = Form.useWatch([], form)
    const staffsQueryResponse = useAllStaffQuery()
    const shopsQueryResponse = useAllTikTokShopQuery()
    const productQueryResponse = useProductQuery({
        page_size: pageSize,
        page_number: pageNumber,
        search,
        ...formWatch,
        status: Number(selectedTab),
        start_time:
            form.getFieldValue('created_time') && form.getFieldValue('created_time')[0]
                ? form.getFieldValue('created_time')[0].valueOf()
                : undefined,
        end_time:
            form.getFieldValue('created_time') && form.getFieldValue('created_time')[1]
                ? form.getFieldValue('created_time')[1].valueOf()
                : undefined
    })

    const refetchData = () => {
        productQueryResponse.refetch()
    }

    const onChangeTab = (key: any) => {
        setSelectedTab(Number(key))
        setPageNumber(1)
    }

    const handleChangePagination = (_pageNumber: number, _pageSize: number) => {
        if (pageSize !== _pageSize) {
            setPageSize(_pageSize)
        } else if (pageNumber !== _pageNumber) {
            setPageNumber(_pageNumber)
        }
    }

    const toggleAddModal = useCallback(
        (value: boolean) => {
            setVisibleAddModal(value)
        },
        [visibleAddModal]
    )

    const toggleEditModal = useCallback(
        (value: boolean) => {
            setVisibleEditModal(value)
        },
        [visibleEditModal]
    )

    const renderListShops = () => {
        return _.map(shopsQueryResponse?.data, (shop: any) => {
            return (
                <Option value={shop._id} key={shop._id} textSearch={`${shop.profile_name} ${shop.name}`}>
                    <Tag color='#1677ff'>{shop.profile_name ? `${shop.profile_name}` : ''}</Tag>
                    {shop.name}
                </Option>
            )
        })
    }
    const renderStaffs = () => {
        return _.map(staffsQueryResponse?.data, (staff: any) => {
            return (
                <Option value={staff._id} key={staff._id}>
                    {staff.full_name}
                </Option>
            )
        })
    }
    const onEditProduct = (product: IProduct) => {
        setSelectedProduct(product)
        toggleEditModal(true)
    }
    const onRemoveProduct = (_id: string) => {
        ProductAPI.removeProduct(_id)
            .then((res) => {
                notify('success', 'Xóa sản phẩm thành công')
                refetchData()
            })
            .catch((err) => {
                notify('error', err.message)
            })
    }

    const onSearch = (value: string) => {
        setSearch(value)
        setPageNumber(1)
    }
    const onChangeValues = (values: any) => {
        setPageNumber(1)
        if (_.has(values, 'created_time')) {
            if (values?.created_time && values?.created_time[0] && values?.created_time[1]) {
                values.created_time[1] = values.created_time[1].endOf('day')
                form.setFieldValue('created_time', values.created_time)
            }
        }
    }

    const columns: ColumnsType<IProduct> = [
        {
            title: 'Ảnh Preview',
            dataIndex: 'images',
            key: 'images',
            render: (text: any, record: any, index: number) => {
                return (
                    <Link to={text && text[0] && text[0]?.url_list[0]} target='_blank'>
                        <Image src={text && text[0] && text[0]?.url_list[0]} width={200} preview={false} />
                    </Link>
                )
            },
            width: 200,
            align: 'center'
        },
        {
            title: 'Tên sản phẩm',
            dataIndex: 'product_name',
            key: 'product_name',
            render: (text: string, record: IProduct) => {
                return <CellProductName product_id={record.product_id} product_name={text} skus={record.skus} tiktok_shop={record.tiktok_shop} />
            },
            width: 300
        },
        {
            title: 'SKU',
            dataIndex: 'skus',
            key: 'skus',
            render: (text: any[], record: any, index: number) => {
                if (text && text[0]) {
                    return <p>{text[0].seller_sku}</p>
                }
                return <p>Không có SKU</p>
            },
            width: 120,
            align: 'center'
        },
        {
            title: 'Giá bán lẻ',
            dataIndex: 'sale_price_ranges',
            key: 'sale_price_ranges',
            render: (text: [], record: any, index: number) => {
                return text.map((sku: any, index: number) => {
                    return <p key={sku.index}>{sku.price_range}</p>
                })
            },
            width: 80,
            align: 'center'
        },
        {
            title: 'Trạng thái',
            dataIndex: 'product_status_view',
            key: 'product_status_view',
            render: (text: any) => {
                if (text && text.product_main_status === 1) {
                    return <Tag color='#389e0d'>LIVE</Tag>
                } else if (text && text.product_main_status === 3) {
                    if (text.product_sub_status === 2) {
                        return <Tag color='#ff4d4f'>FAILED</Tag>
                    } else if (text.product_sub_status === 3) {
                        return <Tag color='#1677ff'>REVIEWING</Tag>
                    } else {
                        return <Tag color='#cf1322'>FROZEN</Tag>
                    }
                } else if (text && text.product_main_status === 5) {
                    return <Tag color='#595959'>DELETED</Tag>
                } else {
                    return <Tag color='#8c8c8c'>Unknown</Tag>
                }
            },
            width: 100,
            align: 'center'
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'created_time',
            key: 'created_time',
            render: (text: any, record: any) => moment(text).format('DD/MM/YYYY HH:mm'),
            width: 150,
            align: 'center'
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (text: any, record: any) => {
                if (record?.product_status_view?.product_main_status !== 5) {
                    return (
                        <Space size='large'>
                            <EditOutlined
                                style={{ color: blue.primary, fontSize: '20px' }}
                                type='button'
                                onClick={() => onEditProduct(record)}
                                title='Edit product'
                            />
                            <Popconfirm title='Xóa sản phẩm này?' onConfirm={() => onRemoveProduct(record._id)}>
                                <DeleteOutlined style={{ color: red.primary, fontSize: '20px' }} title='Xóa sản phẩm' />
                            </Popconfirm>
                        </Space>
                    )
                }
                return null
            },
            width: 150,
            fixed: 'right',
            align: 'center'
        }
    ]

    const LIST_TABS = [
        {
            id: 1,
            label: 'Live',
            icon: <CheckCircleOutlined />
        },
        {
            id: 3,
            label: 'Reviewing',
            icon: <HourglassOutlined />
        },
        {
            id: 2,
            label: 'Failed',
            icon: <CloseCircleOutlined />
        },
        {
            id: 4,
            label: 'Frozen',
            icon: <StopOutlined />
        },
        {
            id: 5,
            label: 'Deleted',
            icon: <DeleteOutlined />
        },
        {
            id: -1,
            label: 'All',
            icon: <MenuUnfoldOutlined />
        }
    ]

    return (
        <Card title='Danh sách sản phẩm'>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Form form={form} onValuesChange={onChangeValues}>
                <Row justify='end' className='mb-3' gutter={[20, 20]}>
                    <Col span={16}>
                        <Row gutter={[20, 20]} className='mb-4'>
                            <Col span={14}>
                                <Search
                                    placeholder='Tìm kiếm theo: Tên sản phẩm, ID, Profile, Template Name'
                                    allowClear
                                    enterButton='Search'
                                    onSearch={_.debounce(onSearch, 250)}
                                    size='large'
                                />
                            </Col>
                        </Row>
                        <Row gutter={[20, 20]}>
                            <Col span={8}>
                                <Form.Item name='shop_id'>
                                    <Select
                                        allowClear
                                        showSearch
                                        optionFilterProp='textSearch'
                                        style={{ width: '100%' }}
                                        placeholder='Tất cả cửa hàng'
                                        size='large'
                                    >
                                        <Option value=''>Tất cả cửa hàng</Option>
                                        {renderListShops()}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name='assignee_id'>
                                    <Select
                                        allowClear
                                        showSearch
                                        optionFilterProp='textSearch'
                                        style={{ width: '100%' }}
                                        placeholder='Tất cả nhân viên'
                                        size='large'
                                    >
                                        <Option value=''>Tất cả nhân viên</Option>
                                        {renderStaffs()}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name='created_time'>
                                    <RangePicker size='large' format='DD-MM-YYYY' />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                        <Space className='mr-2' size='large'>
                            <Button type='primary' onClick={() => toggleAddModal(true)} size='large'>
                                Đồng bộ sản phẩm
                            </Button>
                            <Button type='primary' onClick={refetchData} icon={<ReloadOutlined />} size='large'>
                                Refresh
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>

            <Tabs
                activeKey={selectedTab.toString()}
                defaultActiveKey='1'
                type='card'
                items={LIST_TABS.map((tab, i) => {
                    return {
                        key: tab.id.toString(),
                        label: tab.label,
                        children: (
                            <Table
                                bordered
                                dataSource={productQueryResponse?.data?.data ?? []}
                                columns={columns}
                                loading={productQueryResponse?.isFetching || loading}
                                pagination={{
                                    defaultCurrent: pageNumber,
                                    current: pageNumber,
                                    pageSize,
                                    total: productQueryResponse?.data?.pagination?.total,
                                    onChange: handleChangePagination,
                                    position: ['topLeft', 'bottomRight'],
                                    showTotal: (total) => <HeaderTotalTable total={total} text='Tổng số sản phẩm:' />
                                }}
                                rowKey={(record) => record?._id}
                                scroll={{
                                    x: 500
                                }}
                            />
                        ),
                        icon: tab.icon
                    }
                })}
                onChange={onChangeTab}
            />

            <SyncProductModal visible={visibleAddModal} toggleModal={toggleAddModal} />
            <EditProductModal visible={visibleEditModal} toggleModal={toggleEditModal} refetch={refetchData} item={selectedProduct} />
        </Card>
    )
}

export default ProductManagement
