import { useQuery } from '@tanstack/react-query'
import ProductAPI from 'api/ProductAPI'
import { IProductQueryParams } from 'types/IProduct'

export const TIKTOK_PRODUCT_KEY = 'product_list'

export const useProductQuery = (queryParams: IProductQueryParams) => {
    return useQuery({
        queryKey: [TIKTOK_PRODUCT_KEY, queryParams],
        queryFn: () => ProductAPI.getProducts(queryParams)
    })
}