import { useAuth } from 'contexts/Authenticate'
import _ from 'lodash'

const Header = () => {
    const { logout, user } = useAuth()
    const handleLogout = () => {
        logout()
    }
    return (
        <nav className='navbar navbar-expand navbar-light bg-white'>
            <div className='navbar-collapse collapse'>
                <ul className='navbar-nav ml-auto'>
                    <li className='nav-item dropdown'>
                        <a className='nav-icon dropdown-toggle d-inline-block d-sm-none' href='#' data-toggle='dropdown'>
                            <i className='align-middle' data-feather='settings' />
                        </a>
                        <a className='nav-link dropdown-toggle d-none d-sm-inline-block' href='#' data-toggle='dropdown'>
                            <span className='text-dark'>{user && user.email}</span>
                        </a>
                        <div className='dropdown-menu dropdown-menu-right'>
                            <a className='dropdown-item' href='' onClick={handleLogout}>
                                Sign out
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default Header
