import { useEffect, useState, FC, useRef } from 'react'
import { Button, Form, Modal, Divider } from 'antd'
import _ from 'lodash'
import { notify } from 'contexts/Notification'

import { IListing } from 'types/IListing'
import ListingAPI from 'api/ListingAPI'
import Editor from 'core/Editor'
import { Col, Row, Select, Cascader } from 'antd/lib'
import ProductProperties, { IProductProperties } from 'core/ProductProperties'
const { Option } = Select
type Props = {
    visible: boolean
    toggleModal: (val: boolean) => void
    refetch: () => void
    item: IListing | any
}

const EditDescriptionModal: FC<Props> = ({ visible, toggleModal, refetch, item }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const [description, setDescription] = useState('')
    const [category, setCategory] = useState<string[]>([])
    const [productProperties, setProductProperties] = useState<IProductProperties[]>([])
    useEffect(() => {
        if (visible) {
            setDescription(item?.description)
            setCategory([item?.category_id])
            setProductProperties(item?.product_properties)
        }
    }, [visible, item])
    const onClose = () => {
        form.resetFields()
        setDescription('')
        setCategory([])
        setProductProperties([])
        toggleModal(false)
    }
    const onFinish = async (values: any) => {
        console.log('Success:', values)
        try {
            if (!description) {
                notify('error', 'Vui lòng nhập mô tả sản phẩm!')
                return
            }
            const diff: any = {
                description
            }
            if (productProperties) {
                diff.product_properties = productProperties
            }
            ListingAPI.editListing(item._id, diff)
                .then((res) => {
                    notify('success', 'Cập nhật thành công')
                    onClose()
                    refetch()
                })
                .catch((err) => {
                    notify('error', err.message)
                })
                .finally(() => setLoading(false))
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo)
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo)
    }

    const onChangeDescription = (content: any, editor: any) => {
        if (editor?.getData()) {
            setDescription(editor.getData())
        }
    }

    const handleChangeProductProperties = (product_properties: IProductProperties[]) => {
        console.log('handleChangeProductProperties', product_properties)
        setProductProperties(product_properties)
    }

    return (
        <Modal
            maskClosable={false}
            closable
            open={visible}
            onCancel={onClose}
            title='Sửa sản phẩm Listing'
            footer={null}
            width={1280}
            centered
            style={{ margin: '25px' }}
        >
            <Divider />
            <Form name='EditDescriptionModal' layout='vertical' onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete='off' form={form}>
                <p className='m-2'>
                    <b>Mô tả sản phẩm</b>
                </p>
                <div className='mb-3'>
                    <Editor onChangeEditor={onChangeDescription} initialData={description} />
                </div>
                <Row gutter={[20, 20]}>
                    <ProductProperties category={category} cbChangeValues={handleChangeProductProperties} initialData={item?.product_properties} />
                </Row>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type='primary' htmlType='submit' loading={loading}>
                        Lưu
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default EditDescriptionModal
