import { FC } from 'react'
import { Helmet } from 'react-helmet'
const TITLE = 'Dashboard'
const Dashboard: FC = () => {
    return (
        <div className='container d-flex flex-column'>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            Tik Success Version: 1.0.7
        </div>
    )
}

export default Dashboard
