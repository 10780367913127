import Router from 'core/Routes'
import SideBar from 'core/SideBar'
import Header from 'core/Header'
import { Suspense } from 'react'
import { Spin } from 'antd'

const Wrapper = () => (
    <>
        <SideBar />
        <div className='main'>
            <Header />
            <Suspense
                fallback={
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            right: '50%'
                        }}
                    >
                        <Spin size='large' />
                    </div>
                }
            >
                <main className='main-content'>
                    <Router />
                </main>
            </Suspense>
        </div>
    </>
)

export default Wrapper
