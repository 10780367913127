import { useQuery } from '@tanstack/react-query'
import PromotionAPI from 'api/PromotionAPI'
import { IPromotionQueryParams } from 'types/IPromotion'

export const TIKTOK_PROMOTION_KEY = 'promotion_list'

export const usePromotionQuery = (queryParams: IPromotionQueryParams) => {
    return useQuery({
        queryKey: [TIKTOK_PROMOTION_KEY, queryParams],
        queryFn: () => PromotionAPI.getPromotions(queryParams)
    })
}