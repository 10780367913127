import { useEffect, useState, FC } from 'react'
import { Button, Form, Modal, Divider, Input, DatePicker, Row, Col, Select, Tag } from 'antd'
import _ from 'lodash'
import { notify } from 'contexts/Notification'
import { IPayloadCreatePromotion, IPromotion } from 'types/IPromotion'
import PromotionAPI from 'api/PromotionAPI'
import dayjs from 'dayjs'
import { useAllTikTokShopQuery } from 'hooks/queries/tiktok-shop'

const { Option } = Select
type Props = {
    visible: boolean
    toggleModal: (val: boolean) => void
    refetch: () => void
    item: IPromotion | any
}

const PromotionModal: FC<Props> = ({ visible, toggleModal, refetch, item }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const shopsQueryResponse = useAllTikTokShopQuery()
    useEffect(() => {
        if (visible && item) {
            form.setFieldsValue({
                title: item.title,
                shop_id: item?.tiktok_shop?._id,
                activity_type: item.activity_type,
                product_level: item.product_level,
                begin_time: dayjs.unix(item.begin_time),
                end_time: dayjs.unix(item.end_time)
            })
        }
    }, [visible, item])

    const onClose = () => {
        form.resetFields()
        toggleModal(false)
    }
    const onFinish = async (values: IPayloadCreatePromotion) => {
        console.log('Success:', values)
        const payload = {
            ...values,
            begin_time: dayjs(values.begin_time).unix(),
            end_time: dayjs(values.end_time).unix()
        }
        if (item) {
            setLoading(true)
            PromotionAPI.editPromotion(item._id, payload)
                .then((res) => {
                    notify('success', 'Cập nhật Promotion thành công')
                    onClose()
                    refetch()
                })
                .catch((err) => {
                    notify('error', err.message)
                })
                .finally(() => setLoading(false))
            return
        } else {
            setLoading(true)
            PromotionAPI.createPromotion(payload)
                .then((res) => {
                    notify('success', 'Tạo Promotion thành công')
                    onClose()
                    refetch()
                })
                .catch((err) => {
                    notify('error', err.message)
                })
                .finally(() => setLoading(false))
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo)
    }

    const renderListShops = () => {
        return _.map(shopsQueryResponse?.data, (shop: any) => {
            return (
                <Option value={shop._id} key={shop._id} textSearch={`${shop.profile_name} ${shop.name}`}>
                    <Tag color='#1677ff'>{shop.profile_name ? `${shop.profile_name}` : ''}</Tag>
                    {shop.name}
                </Option>
            )
        })
    }

    return (
        <Modal
            maskClosable={false}
            closable
            open={visible}
            onCancel={onClose}
            title={item ? 'Chỉnh sửa Promotion' : 'Tạo Promotion'}
            footer={null}
            width={1280}
            centered
            style={{ margin: '25px' }}
        >
            <Divider />
            <Form name='PromotionModal' layout='vertical' onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete='off' form={form}>
                <Form.Item label={<b>Shop</b>} name='shop_id' rules={[{ required: true, message: 'Vui lòng chọn cửa hàng!' }]}>
                    <Select allowClear showSearch optionFilterProp='textSearch' style={{ width: '100%' }} placeholder='Chọn cửa hàng' size='large'>
                        {renderListShops()}
                    </Select>
                </Form.Item>

                <Form.Item label={<b>Promotion name</b>} name='title' rules={[{ required: true, message: 'Vui lòng nhập tên Promotion!' }]}>
                    <Input placeholder='Nhập Tên Promotion' size='large' />
                </Form.Item>
                <Form.Item label={<b>Loại Promotion</b>} name='activity_type' rules={[{ required: true, message: 'Vui lòng chọn loại Promotion!' }]}>
                    <Select
                        allowClear
                        showSearch
                        optionFilterProp='textSearch'
                        style={{ width: '100%' }}
                        placeholder='Chọn loại promotion'
                        size='large'
                    >
                        <Option value='FLASHSALE'>FLASH SALE</Option>
                        <Option value='FIXED_PRICE'>FIXED_PRICE</Option>
                        <Option value='DIRECT_DISCOUNT'>DIRECT_DISCOUNT</Option>
                    </Select>
                </Form.Item>
                <Form.Item label={<b>Products</b>} name='product_level' rules={[{ required: true, message: 'Vui lòng chọn loại Products!' }]}>
                    <Select
                        allowClear
                        showSearch
                        optionFilterProp='textSearch'
                        style={{ width: '100%' }}
                        placeholder='Chọn loại Products'
                        size='large'
                    >
                        <Option value='PRODUCT'>PRODUCT-LEVEL</Option>
                        <Option value='VARIATION'>VARIATION-LEVEL</Option>
                    </Select>
                </Form.Item>
                <Row gutter={[20, 20]}>
                    <Col span={6}>
                        <Form.Item
                            label={<b>Thời gian bắt đầu</b>}
                            name='begin_time'
                            rules={[{ required: true, message: 'Vui lòng Chọn thời gian bắt đầu' }]}
                        >
                            <DatePicker showTime size='large' format='YYYY-MM-DD HH:mm' disabledDate={(current) => current && current < dayjs()} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={<b>Thời gian kết thúc</b>}
                            name='end_time'
                            rules={[{ required: true, message: 'Vui lòng Chọn thời gian kết thúc' }]}
                        >
                            <DatePicker showTime size='large' format='YYYY-MM-DD HH:mm' disabledDate={(current) => current && current < dayjs()} />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type='primary' htmlType='submit' loading={loading} size='large'>
                        {item ? 'Lưu Promotion' : 'Tạo Promotion'}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default PromotionModal
