import { message, Tag, Tooltip } from 'antd'
import { FC } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import RedesignBadge from '../../../pages/ListingProduct/AutoIdea/components/RedesignBadge'
import { ITikTokShop } from 'types/ITikTokShop'
type Props = {
    product_name: string
    product_id: string
    skus: any[]
    tiktok_shop: ITikTokShop
    is_clone?: boolean
    removed?: boolean
}

const CellProductName: FC<Props> = ({ product_name, product_id, skus, tiktok_shop, is_clone, removed = false }) => {
    const renderSubNiches = (sub_niches: any) => {
        return sub_niches.map((niche: any, idx: number) => {
            return (
                <span key={niche._id}>
                    {niche.name}
                    {idx !== sub_niches.length - 1 && ';'}
                </span>
            )
        })
    }
    const onCopy = (value: string) => {
        message.success(`Đã Copied: ${value}`)
    }
    return (
        <>
            {is_clone && (
                <>
                    <RedesignBadge />
                    <div className='mt-2' />
                </>
            )}
            <CopyToClipboard text={product_name} onCopy={() => onCopy(product_name)}>
                <Tooltip placement='top' title={product_name}>
                    <span
                        className='clickable'
                        style={{
                            ...(removed && {
                                textDecoration: 'line-through'
                            })
                        }}
                    >
                        {product_name && product_name.length > 255 ? product_name.substring(0, 255) + '...' : product_name}
                    </span>
                </Tooltip>
            </CopyToClipboard>
            {product_id && (
                <CopyToClipboard text={product_id} onCopy={() => onCopy(product_id)}>
                    <p style={{ color: 'rgba(0,0,0,.55)' }} className='clickable'>
                        <i>
                            <b style={{ color: '#000' }}>ID:</b> {product_id}
                        </i>
                    </p>
                </CopyToClipboard>
            )}
            {skus && skus[0]?.seller_sku && (
                <p style={{ color: '#000' }}>
                    <i>
                        <b style={{ color: '#000' }}>SKU:</b> {skus[0]?.seller_sku}
                    </i>
                </p>
            )}
            <CopyToClipboard text={tiktok_shop?.name} onCopy={() => onCopy(tiktok_shop?.name)}>
                <p style={{ color: 'rgba(0,0,0,.55)' }} className='mb-2 clickable'>
                    <b style={{ color: '#000' }}>Shop: </b>
                    <Tag color='#1677ff'>{tiktok_shop?.name}</Tag>
                </p>
            </CopyToClipboard>
            <CopyToClipboard text={tiktok_shop?.profile_name} onCopy={() => onCopy(tiktok_shop?.profile_name)}>
                <p style={{ color: 'rgba(0,0,0,.55)' }} className='mb-2 clickable'>
                    <b style={{ color: '#000' }}>Profile: </b>
                    <Tag color='#1677ff'>{tiktok_shop?.profile_name}</Tag>
                </p>
            </CopyToClipboard>

            {tiktok_shop?.main_niche && (
                <p style={{ color: 'rgba(0,0,0,.55)' }} className='mb-2'>
                    <b style={{ color: '#000' }}>Niche: </b> <Tag color='#08979c'>{tiktok_shop?.main_niche?.name}</Tag>
                </p>
            )}
            {tiktok_shop?.sub_niches && tiktok_shop?.sub_niches.length > 0 && (
                <p style={{ color: 'rgba(0,0,0,.55)' }}>
                    <b style={{ color: '#000' }}>Sub-Niche: </b> <Tag color='#08979c'>{renderSubNiches(tiktok_shop?.sub_niches)}</Tag>
                </p>
            )}
            {tiktok_shop?.assignee && (
                <p style={{ color: 'rgba(0,0,0,.55)' }} className='mt-2'>
                    <b style={{ color: '#000' }}>Nhân viên: </b> <Tag color='#d46b08'>{tiktok_shop?.assignee.full_name}</Tag>
                </p>
            )}
        </>
    )
}

export default CellProductName
