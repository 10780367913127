import ApiSender from './ApiSender'

export default class TikTokShopAPI {
    public static oauthTikTokAccount(params: object) {
        const route = `/core/user/oauth/tiktok-shop`
        return ApiSender.get(route, params)
    }
    public static getTikTokShops(params?: { page_size: number; page_number: number; search?: string }) {
        const route = `/core/user/all-tiktok-shops`
        return ApiSender.get(route, params)
    }

    public static getTikTokShopsWithPaging(params: {
        page_size: number
        page_number: number
        search?: string
        sort?: string
        assignee_id?: string
        status_kbt?: any
        niche_id?: string
    }) {
        const route = `/core/user/tiktok-shops`
        return ApiSender.get(route, params)
    }

    public static importFromKiki(payload: { profile_id: string }) {
        const route = `/core/user/import-kiki`
        return ApiSender.post(route, payload)
    }

    public static syncKikiProfile(shop_id: string) {
        const route = `/core/user/sync-kiki-profile`
        return ApiSender.post(route, { shop_id })
    }
    public static getShopsHealth(params: { page_size: number; page_number: number; search?: string }) {
        const route = `/core/user/shops-health`
        return ApiSender.get(route, params)
    }

    public static editShop(
        _id: string,
        payload: { main_niche: string; sub_niche_ids?: string[]; key_words?: string; authorize_token?: string; assignee_id?: string }
    ) {
        const route = `/core/user/tiktok-shops/${_id}`
        return ApiSender.put(route, payload)
    }
    public static removeShop(_id: string) {
        const route = `/core/user/tiktok-shops/${_id}`
        return ApiSender.delete(route)
    }
    public static syncAuthTokenTiktok(shop_id: string) {
        const route = `/core/user/sync-authorize-token`
        return ApiSender.post(route, { shop_id })
    }
    public static migrateStaff(payload: { src_staff_id: string; dest_staff_id: string }) {
        const route = `/core/user/tiktok-shops/migrate-staff`
        return ApiSender.post(route, payload)
    }

    public static syncKBT(shop_id: string) {
        const route = `/core/user/sync-kbt`
        return ApiSender.post(route, { shop_id })
    }
}
