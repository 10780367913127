import { useQuery } from '@tanstack/react-query'
import TikTokShopAPI from 'api/TikTokShopAPI'
import { ITikTokShop, ITiktokShopQueryParams } from 'types/ITikTokShop'

export const TIKTOK_SHOP_QUERY_KEY = 'tiktok_shop_list'

export const ALL_TIKTOK_SHOP_QUERY_KEY = 'all_tiktok_shop_list'

export const useTikTokShopQuery = (queryParams: ITiktokShopQueryParams) => {
    return useQuery({
        queryKey: [TIKTOK_SHOP_QUERY_KEY, queryParams],
        queryFn: () => TikTokShopAPI.getTikTokShopsWithPaging(queryParams)
    })
}

export const useAllTikTokShopQuery = () => {
    return useQuery<ITikTokShop[]>({
        queryKey: [ALL_TIKTOK_SHOP_QUERY_KEY],
        queryFn: () => TikTokShopAPI.getTikTokShops()
    })
}
