import { Card, Table, Row, Select, Space, Button, Image, Tag, Tooltip, InputNumber, Input, Form, Typography, Popconfirm, Col, DatePicker } from 'antd'
import { useEffect, useState, FC, useCallback } from 'react'
import { EditOutlined, ReloadOutlined, DeleteOutlined } from '@ant-design/icons'
import { blue, red } from '@ant-design/colors'
import moment from 'moment'
import _ from 'lodash'
import { notify } from 'contexts/Notification'
import { IProduct } from 'types/IProduct'
import ProductAPI from 'api/ProductAPI'
import Search from 'antd/es/input/Search'
import { Link } from 'react-router-dom'
import CellProductName from 'core/Table/CellProductName'
import { useAllStaffQuery } from 'hooks/queries/staff'
import { useAllTikTokShopQuery } from 'hooks/queries/tiktok-shop'
import { useProductQuery } from 'hooks/queries/product'
import HeaderTotalTable from 'core/Table/HeaderTotalTable'
const { Option } = Select
const { RangePicker } = DatePicker
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean
    dataIndex: string
    title: any
    inputType: 'number' | 'text'
    record: IProduct
    index: number
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`
                        }
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    )
}

const ListTemplate: FC<any> = ({ tab }: { tab: number }) => {
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [pageSize, setPageSize] = useState(10)
    const [pageNumber, setPageNumber] = useState(1)
    const [form] = Form.useForm()
    const [formFilter] = Form.useForm()
    const formWatch = Form.useWatch([], formFilter)
    const [editingKey, setEditingKey] = useState('')
    const staffsQueryResponse = useAllStaffQuery()
    const shopsQueryResponse = useAllTikTokShopQuery()
    const productQueryResponse = useProductQuery({
        page_size: pageSize,
        page_number: pageNumber,
        is_template_listing: true,
        search,
        ...formWatch,
        start_time:
            formFilter.getFieldValue('created_time') && formFilter.getFieldValue('created_time')[0]
                ? formFilter.getFieldValue('created_time')[0].valueOf()
                : undefined,
        end_time:
            formFilter.getFieldValue('created_time') && formFilter.getFieldValue('created_time')[1]
                ? formFilter.getFieldValue('created_time')[1].valueOf()
                : undefined
    })

    const refetchData = () => {
        productQueryResponse?.refetch()
    }

    const handleChangePagination = (_pageNumber: number, _pageSize: number) => {
        if (pageSize !== _pageSize) {
            setPageSize(_pageSize)
        } else if (pageNumber !== _pageNumber) {
            setPageNumber(_pageNumber)
        }
    }

    const onRemoveTemplateShop = (_id: string, is_template_listing: boolean) => {
        ProductAPI.editProduct(_id, { is_template_listing })
            .then((res) => {
                notify('success', 'Bỏ Template thành công')
                refetchData()
            })
            .catch((err) => {
                notify('error', err.message)
            })
    }

    const isEditing = (record: IProduct) => record._id === editingKey

    const edit = (record: IProduct) => {
        form.setFieldsValue({ template_name: record.template_name })
        setEditingKey(record._id)
    }

    const cancel = () => {
        setEditingKey('')
    }
    const save = async (_id: string) => {
        try {
            const row = (await form.validateFields()) as IProduct

            console.log('newData', row)
            if (row.template_name) {
                setLoading(true)
                ProductAPI.editProduct(_id, { template_name: row.template_name })
                    .then((res) => {
                        notify('success', 'Cập nhật thành công')
                        refetchData()
                        setEditingKey('')
                    })
                    .catch((err) => {
                        notify('error', err.message)
                    })
                    .finally(() => setLoading(false))
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo)
        }
    }

    const renderListShops = () => {
        return _.map(shopsQueryResponse?.data, (shop: any) => {
            return (
                <Option value={shop._id} key={shop._id} textSearch={`${shop.profile_name} ${shop.name}`}>
                    <Tag color='#1677ff'>{shop.profile_name ? `${shop.profile_name}` : ''}</Tag>
                    {shop.name}
                </Option>
            )
        })
    }

    const renderStaffs = () => {
        return _.map(staffsQueryResponse?.data, (staff: any) => {
            return (
                <Option value={staff._id} key={staff._id}>
                    {staff.full_name}
                </Option>
            )
        })
    }

    const onSearch = (value: string) => {
        setSearch(value)
        setPageNumber(1)
    }

    const onChangeValues = (values: any) => {
        setPageNumber(1)
        if (_.has(values, 'created_time')) {
            if (values?.created_time && values?.created_time[0] && values?.created_time[1]) {
                values.created_time[1] = values.created_time[1].endOf('day')
                form.setFieldValue('created_time', values.created_time)
            }
        }
    }

    const columns = [
        {
            title: 'Ảnh Preview',
            dataIndex: 'images',
            key: 'images',
            render: (text: any, record: any, index: number) => {
                return (
                    <Link to={text && text[0] && _.isArray(text[0]?.url_list) && text[0]?.url_list[0]} target='_blank'>
                        <Image src={text && text[0] && text[0]?.url_list[0]} width={200} preview={false} />
                    </Link>
                )
            },
            width: 230,
            align: 'center'
        },
        {
            title: 'Tên Template',
            dataIndex: 'template_name',
            key: 'template_name',
            render: (text: string) => {
                return (
                    <>
                        <Tooltip placement='top' title={text}>
                            {text && text.length > 255 ? text.substring(0, 255) + '...' : text}
                        </Tooltip>
                    </>
                )
            },
            width: 150,
            editable: true
        },
        {
            title: 'Thông tin sản phẩm',
            dataIndex: 'product_name',
            key: 'product_name',
            render: (text: string, record: IProduct) => {
                return <CellProductName product_id={record.product_id} product_name={text} skus={record.skus} tiktok_shop={record.tiktok_shop} />
            },
            width: 300
        },
        {
            title: 'SKU',
            dataIndex: 'skus',
            key: 'skus',
            render: (text: any[], record: any, index: number) => {
                if (text && text[0]) {
                    return <p>{text[0].seller_sku}</p>
                }
                return <p>Không có SKU</p>
            },
            width: 120,
            align: 'center'
        },
        {
            title: 'Giá bán',
            dataIndex: 'sale_price_ranges',
            key: 'sale_price_ranges',
            render: (text: [], record: any, index: number) => {
                return text.map((sku: any, index: number) => {
                    return <p key={sku.index}>{sku.price_range}</p>
                })
            },
            width: 100,
            align: 'center'
        },
        {
            title: 'Trạng thái',
            dataIndex: 'product_status_view',
            key: 'product_status_view',
            render: (text: any) => {
                if (text && text.product_main_status === 1) {
                    return <Tag color='#389e0d'>LIVE</Tag>
                } else if (text && text.product_main_status === 3) {
                    if (text.product_sub_status === 2) {
                        return <Tag color='#ff4d4f'>Failed</Tag>
                    } else if (text.product_sub_status === 3) {
                        return <Tag color='#1677ff'>Reviewing</Tag>
                    } else {
                        return <Tag color='#cf1322'>Frozen</Tag>
                    }
                } else {
                    return <Tag color='#8c8c8c'>Unknown</Tag>
                }
            },
            width: 100,
            align: 'center'
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'created_time',
            key: 'created_time',
            render: (text: any, record: any) => moment(text).format('DD/MM/YYYY HH:mm'),
            width: 150,
            align: 'center'
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (text: any, record: any) => {
                const editable = isEditing(record)
                return editable ? (
                    <span>
                        <Typography.Link onClick={() => save(record._id)} style={{ marginInlineEnd: 15 }}>
                            <span style={{ color: 'blue' }}>
                                <b>Save</b>
                            </span>
                        </Typography.Link>
                        <Typography.Link onClick={cancel} style={{ marginInlineEnd: 8 }}>
                            <span style={{ color: 'red', cursor: 'pointer' }}>
                                <b>Cancel</b>
                            </span>
                        </Typography.Link>
                    </span>
                ) : (
                    <Space size='large'>
                        <EditOutlined
                            style={{ color: blue.primary, fontSize: '20px' }}
                            type='button'
                            onClick={() => edit(record)}
                            title='Edit template'
                        />
                        <Popconfirm title='Bạn chắc chắn xóa sản phẩm này khỏi Template?' onConfirm={() => onRemoveTemplateShop(record._id, false)}>
                            <DeleteOutlined style={{ color: red.primary, fontSize: '20px' }} type='button' title='Remove template' />
                        </Popconfirm>
                    </Space>
                )
            },
            width: 150,
            fixed: 'right',
            align: 'center'
        }
    ]

    const mergedColumns: any = columns.map((col) => {
        if (!col.editable) {
            return col
        }
        return {
            ...col,
            onCell: (record: IProduct) => ({
                record,
                inputType: col.dataIndex === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record)
            })
        }
    })

    return (
        <>
            <Form form={formFilter} onValuesChange={onChangeValues}>
                <Row justify='end' className='mb-3' gutter={[20, 20]}>
                    <Col span={16}>
                        <Row gutter={[20, 20]} className='mb-4'>
                            <Col span={14}>
                                <Search
                                    placeholder='Tìm kiếm theo: Tên sản phẩm, ID, Profile, Template Name'
                                    allowClear
                                    enterButton='Search'
                                    onSearch={_.debounce(onSearch, 250)}
                                    size='large'
                                />
                            </Col>
                        </Row>
                        <Row gutter={[20, 20]}>
                            <Col span={8}>
                                <Form.Item name='shop_id'>
                                    <Select
                                        allowClear
                                        showSearch
                                        optionFilterProp='textSearch'
                                        style={{ width: '100%' }}
                                        placeholder='Tất cả cửa hàng'
                                        size='large'
                                    >
                                        <Option value=''>Tất cả cửa hàng</Option>
                                        {renderListShops()}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name='assignee_id'>
                                    <Select
                                        allowClear
                                        showSearch
                                        optionFilterProp='textSearch'
                                        style={{ width: '100%' }}
                                        placeholder='Tất cả nhân viên'
                                        size='large'
                                    >
                                        <Option value=''>Tất cả nhân viêng</Option>
                                        {renderStaffs()}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name='created_time'>
                                    <RangePicker size='large' format='DD-MM-YYYY' />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                        <Button type='primary' onClick={refetchData} icon={<ReloadOutlined />} size='large'>
                            Refresh
                        </Button>
                    </Col>
                </Row>
            </Form>
            <Form form={form} component={false}>
                <Table
                    bordered
                    dataSource={productQueryResponse?.data?.data ?? []}
                    columns={mergedColumns}
                    loading={productQueryResponse?.isFetching || loading}
                    pagination={{
                        defaultCurrent: pageNumber,
                        current: pageNumber,
                        pageSize,
                        total: productQueryResponse?.data?.pagination?.total,
                        onChange: handleChangePagination,
                        position: ['topLeft', 'bottomRight'],
                        showTotal: (total) => <HeaderTotalTable total={total} text='Tổng số Templates:' />
                    }}
                    rowKey={(record) => record?._id}
                    scroll={{
                        x: 800
                    }}
                    components={{
                        body: {
                            cell: EditableCell
                        }
                    }}
                />
            </Form>
        </>
    )
}

export default ListTemplate
