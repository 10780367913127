import { useEffect, useState, FC } from 'react'
import { Row, Col, Select, Space, Button, Tag, Form, Input, Upload, Cascader, InputNumber, Checkbox, ConfigProvider } from 'antd'
import { PlusOutlined, InboxOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { ICategory } from 'types/ICategory'
import CategoryAPI from 'api/CategoryAPI.ts'
import type { UploadFile, UploadProps } from 'antd'

import UploadAPI from 'api/UploadAPI'
import { notify } from 'contexts/Notification'
import ProductAPI from 'api/ProductAPI'
import TikTokShopAPI from 'api/TikTokShopAPI'
import Editor from 'core/Editor'
import ProductProperties, { IProductProperties } from 'core/ProductProperties'
import { BRANDS } from 'const'
import ListingAPI from 'api/ListingAPI'
import { IConfigProduct } from 'types/IConfigProduct'
import ConfigProductAPI from 'api/ConfigProductAPI'
import { useAllDescriptionTemplateQuery } from 'hooks/queries/description-template'
import { useAllPriceTemplateQuery } from 'hooks/queries/price-template'
import { CheckboxProps } from 'antd/lib'
import TextArea from 'antd/es/input/TextArea'
const { Dragger } = Upload
const { Option } = Select
const CATEGORY_DEFAULT = ['824328', '839944', '601226', '1165840']
type Props = {
    is_template_listing?: boolean
    cb?: () => void
    tab?: number
}

const CreateProduct: FC<Props> = ({ cb, tab }) => {
    const [categories, setCategories] = useState<ICategory[]>([])
    const [loading, setLoading] = useState(false)
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [fileListSizeChart, setFileListSizeChart] = useState<UploadFile[]>([])
    const [form] = Form.useForm()
    const [shops, setShops] = useState<any[]>([])
    const [hasColor, setHasColor] = useState(false)
    const [selectedShop, setSelectedShop] = useState<any>(null)
    const [description, setDescription] = useState('')
    const [category, setCategory] = useState<string[]>(CATEGORY_DEFAULT)
    const [productProperties, setProductProperties] = useState<IProductProperties[]>([])
    const [configProducts, setConfigProducts] = useState<IConfigProduct[]>([])
    const descriptionTemplatesQueryResponse = useAllDescriptionTemplateQuery()
    const priceTemplatesQueryResponse = useAllPriceTemplateQuery()
    const [selectedPriceTemp, setSelectedPriceTemp] = useState<string>('')
    const [isTemplateListing, setTemplateListing] = useState<boolean>(true)
    useEffect(() => {
        CategoryAPI.getCategories().then((res) => {
            let options: any = []
            res.map((item: any) => {
                if (item.level === 1) {
                    options.push({
                        value: item.id,
                        label: item.name,
                        children: []
                    })
                }
            })
            res.filter((item: any) => item.level === 2).map((item: any) => {
                options.map((option: any) => {
                    if (item.parent_id === option.value) {
                        option.children.push({
                            value: item.id,
                            label: item.name,
                            children: []
                        })
                    }
                })
            })
            res.filter((item: any) => item.level === 3).map((item: any) => {
                options.map((option: any) => {
                    option.children.map((child: any) => {
                        if (item.parent_id === child.value) {
                            child.children.push({
                                value: item.id,
                                label: item.name,
                                children: []
                            })
                        }
                    })
                })
            })
            res.filter((item: any) => item.level === 4).map((item: any) => {
                options.map((option1: any) => {
                    option1.children.map((option2: any) => {
                        if (option2 && option2.children) {
                            option2.children.map((child: any) => {
                                if (item.parent_id === child.value) {
                                    child.children.push({
                                        value: item.id,
                                        label: item.name
                                    })
                                }
                            })
                        }
                    })
                })
            })
            setCategories(options)
        })
        TikTokShopAPI.getTikTokShops({
            page_size: 100,
            page_number: 1
        }).then((res) => {
            setShops(res)
        })
        ListingAPI.getSKU()
            .then((res) => {
                form.setFieldsValue({ sku: res })
            })
            .catch((err) => {
                notify('error', err.message)
            })
        ConfigProductAPI.getAllConfigProducts()
            .then((res) => {
                setConfigProducts(res)
                const _configProd = res.find((item: any) => item.id === CATEGORY_DEFAULT.join('_'))
                console.log('_configProd', _configProd)
                if (_configProd) {
                    form.setFieldsValue({
                        quantity: _configProd.quantity,
                        brand_id: _configProd.brand_id,
                        height: _configProd.package_height,
                        width: _configProd.package_width,
                        length: _configProd.package_length,
                        weight: _configProd.package_weight
                    })
                    setProductProperties(_configProd.product_properties)
                } else {
                    form.setFieldsValue({
                        quantity: 2,
                        brand_id: '0',
                        height: 9,
                        width: 9,
                        length: 1,
                        weight: 0.42
                    })
                    setProductProperties([])
                }
            })
            .catch((err) => {
                notify('error', err.message)
            })
        onReset()
    }, [tab])
    const onReset = () => {
        console.log('onReset')
        form.resetFields()
        form.setFieldsValue({
            quantity: 2,
            brand_id: '0',
            unit: 'INCH',
            weight_unit: 'POUND',
            colors: 'Black|White|Navy|Sand|Red|SportGrey',
            sizes: 'S|M|L|XL|2XL|3XL',
            skus: [],
            category: CATEGORY_DEFAULT,
            height: 9,
            width: 9,
            length: 1,
            weight: 0.42,
            description_template_id: '',
            price_template_id: ''
        })
        setFileList([])
        setFileListSizeChart([])
        setDescription('')
        setCategory(CATEGORY_DEFAULT)
        setProductProperties([])
        setSelectedShop(null)
        setSelectedPriceTemp('')
        ListingAPI.getSKU()
            .then((res) => {
                form.setFieldsValue({ sku: res })
            })
            .catch((err) => {
                notify('error', err.message)
            })
    }
    const onFinish = async (values: any) => {
        console.log('Success:', values)
        try {
            if (!description) {
                notify('error', 'Vui lòng nhập mô tả sản phẩm!')
                return
            }
            setLoading(true)
            let resImages = await Promise.all(
                values?.images?.fileList.map(async (file: any) => {
                    const resImage = await UploadAPI.uploadImage(file.originFileObj)
                    return resImage
                })
            )
            let payload = {
                ...values,
                images: resImages,
                description,
                product_properties: productProperties,
                default_sku: values.sku,
                is_template_listing: isTemplateListing
            }
            if (values?.size_chart?.fileList) {
                const resSizeChart = await UploadAPI.uploadImage(values.size_chart.fileList[0].originFileObj)
                payload.size_chart_image = resSizeChart
            }
            if (values?.colors) {
                payload.colors = values.colors.split('|')
            }
            if (values?.sizes) {
                payload.sizes = values.sizes.split('|')
            }
            if (!values.skus || values.skus.length === 0) {
                notify('error', 'Vui lòng tạo SKU!')
                return
            }
            // if (!selectedPriceTemp && !values.price) {
            //     notify('error', 'Vui lòng nhập Giá!')
            //     return
            // }
            if (!values.sizes || !values.colors) {
                notify('error', 'Vui lòng nhập Size hoặc Color theo đúng định dạng mẫu!')
                return
            }
            console.log('payload', payload)
            await ProductAPI.uploadProductToTikTok(payload)
            notify('success', 'Upload sản phẩm mẫu đã được đưa vào hàng chờ!')
            onReset()
            if (cb) {
                cb()
            }
        } catch (error: any) {
            notify('error', error.message)
        } finally {
            setLoading(false)
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo)
    }
    const handleChangeImages: UploadProps['onChange'] = ({ fileList: newFileList }) => setFileList(newFileList)
    const handleChangeSizeChart: UploadProps['onChange'] = ({ fileList: newFileList }) => setFileListSizeChart(newFileList)
    const handleChangeProductProperties = (product_properties: IProductProperties[]) => {
        setProductProperties(product_properties)
    }
    const generateSKUS = () => {
        let { colors, sizes, price, sku } = form.getFieldsValue(['colors', 'sizes', 'price', 'sku'])
        console.log(colors, sizes, price, sku)
        let skus: any = []
        colors = colors.split('|')
        sizes = sizes.split('|')
        if (!sizes || sizes.length === 0) {
            notify('error', 'Vui lòng nhập Sizes!')
            return
        }
        if (!price) {
            notify('error', 'Vui lòng nhập Price!')
            return
        }
        if (colors.length > 0) {
            setHasColor(true)
            for (let i = 0; i < sizes.length; i++) {
                for (let j = 0; j < colors.length; j++) {
                    skus.push({
                        size: sizes[i],
                        color: colors[j],
                        price: price,
                        seller_sku: sku
                    })
                }
            }
        } else {
            setHasColor(false)
            for (let i = 0; i < sizes.length; i++) {
                skus.push({
                    size: sizes[i],
                    price: price,
                    seller_sku: sku
                })
            }
        }

        form.setFieldValue('skus', skus)
    }
    const onChangeDescription = (content: any, editor: any) => {
        if (editor?.getData()) {
            setDescription(editor.getData())
        }
    }
    const props: any = {
        name: 'file',
        multiple: true,
        beforeUpload(file: any) {
            return false
        },
        maxCount: 20,
        accept: 'image/*'
    }

    const renderListShops = () => {
        return _.map(shops, (shop: any) => {
            return (
                <Option value={shop._id} key={shop._id} textSearch={`${shop.profile_name} ${shop.name}`}>
                    <Tag color='#1677ff'>{shop.profile_name ? `${shop.profile_name}` : ''}</Tag>
                    {shop.name}
                </Option>
            )
        })
    }

    const renderSubNiches = (sub_niches: any) => {
        return sub_niches.map((niche: any, idx: number) => {
            return (
                <span key={niche._id}>
                    {niche.name}
                    {idx !== sub_niches.length - 1 && ';'}
                </span>
            )
        })
    }
    const renderBrands = () => {
        return _.map(BRANDS, (brand: any) => {
            return (
                <Option value={brand.id} key={brand.id} textSearch={`${brand.name}`}>
                    {`${brand.name}`}
                </Option>
            )
        })
    }
    const onChangeForm = (changedValues: any) => {
        console.log('changedValues', changedValues)
        if (changedValues.shop_id) {
            const shop = shops.find((_item) => _item._id === changedValues.shop_id)
            setSelectedShop(shop)
        }
        if (changedValues.category) {
            setCategory(changedValues.category)
            const _configProd = configProducts.find((item: any) => item.id === changedValues.category.join('_'))
            console.log('onChangeForm _configProd', _configProd)
            if (_configProd) {
                form.setFieldsValue({
                    quantity: _configProd.quantity,
                    brand_id: _configProd.brand_id,
                    height: _configProd.package_height,
                    width: _configProd.package_width,
                    length: _configProd.package_length,
                    weight: _configProd.package_weight
                })
                setProductProperties(_configProd.product_properties as IProductProperties[])
            } else {
                setProductProperties([])
            }
        }
        if (changedValues.description_template_id && descriptionTemplatesQueryResponse?.data) {
            const _description = descriptionTemplatesQueryResponse?.data.find((item: any) => item._id === changedValues.description_template_id)
            if (_description) {
                setDescription(_description.description)
            }
        } else if (changedValues.description_template_id === '') {
            setDescription('')
        }
        if (changedValues.price_template_id) {
            if (priceTemplatesQueryResponse?.data) {
                const _price = priceTemplatesQueryResponse?.data.find((item: any) => item._id === changedValues.price_template_id)
                if (_price) {
                    form.setFieldsValue({
                        colors: _price.colors.join('|'),
                        sizes: _price.sizes.join('|')
                    })
                    setHasColor(_price.colors.length > 0)
                    const skus = _price.mixed_attributes.map((item: any) => {
                        return {
                            color: item.color,
                            size: item.size,
                            price: item.price,
                            seller_sku: form.getFieldValue('sku')
                        }
                    })
                    form.setFieldValue('skus', skus)
                }
            }

            setSelectedPriceTemp(changedValues.price_template_id)
        } else if (changedValues.price_template_id === '') {
            form.setFieldsValue({
                colors: 'Black|White|Navy|Sand|Red|SportGrey',
                sizes: 'S|M|L|XL|2XL|3XL'
            })
            setHasColor(true)
            form.setFieldValue('skus', [])
            setSelectedPriceTemp(changedValues.price_template_id)
        }

        if (changedValues.sku) {
            if (priceTemplatesQueryResponse?.data) {
                const _price = priceTemplatesQueryResponse?.data.find((item: any) => item._id === form.getFieldValue('price_template_id'))
                if (_price) {
                    form.setFieldsValue({
                        colors: _price.colors.join('|'),
                        sizes: _price.sizes.join('|')
                    })
                    setHasColor(_price.colors.length > 0)
                    const skus = _price.mixed_attributes.map((item: any) => {
                        return {
                            color: item.color,
                            size: item.size,
                            price: item.price,
                            seller_sku: changedValues.sku
                        }
                    })
                    form.setFieldValue('skus', skus)
                }
            }
        }
    }
    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type='button'>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    )

    const onChangeIsTemplate: CheckboxProps['onChange'] = (e) => {
        setTemplateListing(e.target.checked)
    }
    return (
        <Form
            name='CreateProductTemplate'
            layout='vertical'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
            form={form}
            initialValues={{
                unit: 'INCH',
                weight_unit: 'POUND',
                colors: 'Black|White|Navy|Sand|Red|SportGrey',
                sizes: 'S|M|L|XL|2XL|3XL',
                skus: [],
                category: CATEGORY_DEFAULT,
                description_template_id: '',
                price_template_id: ''
            }}
            onValuesChange={onChangeForm}
        >
            <Checkbox onChange={onChangeIsTemplate} checked={isTemplateListing} className='ml-1 mt-3 mb-3' style={{ fontSize: '15px' }}>
                Chọn làm Template Product
            </Checkbox>

            <Row gutter={[20, 20]}>
                {isTemplateListing && (
                    <Col span={12}>
                        <Form.Item
                            label={<b>Template Name</b>}
                            name='template_name'
                            rules={[{ required: true, message: 'Vui lòng nhập tên Template!' }]}
                        >
                            <Input placeholder='Nhập Tên Template' size='large' />
                        </Form.Item>
                    </Col>
                )}

                <Col span={12}>
                    <Form.Item label={<b>Shop</b>} name='shop_id' rules={[{ required: true, message: 'Vui lòng Chọn shop!' }]}>
                        <Select
                            allowClear
                            style={{ width: '100%' }}
                            placeholder='Vui lòng Chọn shop'
                            showSearch
                            optionFilterProp='textSearch'
                            size='large'
                        >
                            {renderListShops()}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            {selectedShop && selectedShop?.main_niche && (
                <p style={{ color: 'rgba(0,0,0,.55)' }} className='mb-1'>
                    <b style={{ color: '#000' }}>Niche: </b> <Tag color='#08979c'>{selectedShop?.main_niche?.name}</Tag>
                </p>
            )}
            {selectedShop && selectedShop?.sub_niches && selectedShop?.sub_niches.length > 0 && (
                <p style={{ color: 'rgba(0,0,0,.55)' }} className='mb-3'>
                    <b style={{ color: '#000' }}>Sub-Niche: </b> <Tag color='#08979c'>{renderSubNiches(selectedShop?.sub_niches)}</Tag>
                </p>
            )}
            <Form.Item label={<b>Hình ảnh sản phẩm</b>} name='images' rules={[{ required: true, message: 'Vui lòng chọn ảnh sản phẩm!' }]}>
                <Dragger {...props} listType='picture-circle' fileList={fileList} onChange={handleChangeImages}>
                    <p className='ant-upload-drag-icon'>
                        <InboxOutlined />
                    </p>
                    <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                    <p className='ant-upload-hint'>
                        Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned files.
                    </p>
                </Dragger>
            </Form.Item>
            <Row gutter={[20, 20]}>
                <Col span={12}>
                    <Form.Item label={<b>Title</b>} name='title' rules={[{ required: true, message: 'Vui lòng nhập tên sản phẩm!' }]}>
                        <TextArea placeholder='Nhập Tên sản phẩm' size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={<b>Link Designer</b>} name='link_designer'>
                        <Input placeholder='Nhập Link Designer' size='large' />
                    </Form.Item>
                    <Form.Item label={<b>Link File In</b>} name='link_file_print'>
                        <Input placeholder='Nhập Link File In' size='large' />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[20, 20]}>
                <Col span={12}>
                    <Form.Item label={<b>Category</b>} name='category' rules={[{ required: true, message: 'Vui lòng chọn hạng mục!' }]}>
                        <Cascader options={categories} showSearch size='large' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={<b>Brand</b>} name='brand_id' rules={[{ required: true, message: 'Vui lòng chọn thương hiệu!' }]}>
                        <Select placeholder='Chọn thương hiệu' showSearch optionFilterProp='textSearch' size='large'>
                            <Option value='0'>No Brand</Option>
                            {renderBrands()}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[20, 20]}>
                <ProductProperties category={category} cbChangeValues={handleChangeProductProperties} initialData={productProperties} />
            </Row>
            <Form.Item label={<b>Size chart</b>} name='size_chart'>
                <Upload beforeUpload={() => false} listType='picture-circle' fileList={fileListSizeChart} onChange={handleChangeSizeChart}>
                    {fileListSizeChart.length >= 1 ? null : uploadButton}
                </Upload>
            </Form.Item>
            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <ConfigProvider
                        theme={{
                            components: {
                                Select: {
                                    colorPrimary: 'linear-gradient(90deg, #6a11cb, #2575fc) !important',
                                    colorBgContainer: '#d9d9d9 !important'
                                }
                            }
                        }}
                    >
                        <Form.Item
                            label={<b style={{ fontSize: '15px', color: '#0958d9' }}>* Chọn mẫu mô tả có sẵn</b>}
                            name='description_template_id'
                        >
                            <Select placeholder='Chọn mẫu mô tả có sẵn' showSearch optionFilterProp='textSearch' size='large'>
                                <Option value=''>NHẬP TAY MÔ TẢ</Option>
                                {_.map(descriptionTemplatesQueryResponse?.data, (des: any) => (
                                    <Option value={des._id} key={des._id} textSearch={`${des.name}`}>
                                        {`${des.name}`}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </ConfigProvider>
                </Col>
            </Row>
            <p className='m-2'>
                <b>Description</b>
            </p>
            <div className='mb-3'>
                <Editor onChangeEditor={onChangeDescription} initialData={description} />
            </div>

            <Row gutter={[20, 20]}>
                <Col span={6}>
                    <Form.Item label={<b>Height</b>} name='height' rules={[{ required: true, message: 'Vui lòng nhập Height sản phẩm!' }]}>
                        <InputNumber placeholder='Nhập Height sản phẩm' min={0} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={<b>Width</b>} name='width' rules={[{ required: true, message: 'Vui lòng nhập Width sản phẩm!' }]}>
                        <InputNumber placeholder='Nhập Width phẩm' min={0} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={<b>Length</b>} name='length' rules={[{ required: true, message: 'Vui lòng nhập Length sản phẩm!' }]}>
                        <InputNumber placeholder='Nhập Length sản phẩm' min={0} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={<b>Unit</b>} name='unit' rules={[{ required: true, message: 'Vui lòng nhập Unit!' }]}>
                        <Input placeholder='Nhập Unit' readOnly />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[20, 20]}>
                <Col span={6}>
                    <Form.Item
                        label={<b>Package Weight</b>}
                        name='weight'
                        rules={[{ required: true, message: 'Vui lòng nhập trọng lượng sản phẩm!' }]}
                    >
                        <InputNumber addonAfter='POUND' style={{ width: '100%' }} min={0} placeholder='Nhập Trọng lượng sản phẩm' />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={<b>Quantity</b>} name='quantity' rules={[{ required: true, message: 'Vui lòng nhập số lượng sản phẩm!' }]}>
                        <InputNumber placeholder='Nhập số lượng sản phẩm' min={0} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <ConfigProvider
                        theme={{
                            components: {
                                Select: {
                                    colorPrimary: 'linear-gradient(90deg, #6a11cb, #2575fc) !important',
                                    colorBgContainer: '#d9d9d9 !important'
                                }
                            }
                        }}
                    >
                        <Form.Item
                            label={<b style={{ fontSize: '15px', color: '#0958d9' }}>* Chọn mẫu Size, Price có sẵn</b>}
                            name='price_template_id'
                        >
                            <Select placeholder='Chọn mẫu Size, Price có sẵn' showSearch optionFilterProp='textSearch' size='large'>
                                <Option value=''>NHẬP TAY SIZE, PRICE</Option>
                                {_.map(priceTemplatesQueryResponse?.data, (des: any) => (
                                    <Option value={des._id} key={des._id} textSearch={`${des.name}`}>
                                        {`${des.name}`}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </ConfigProvider>
                </Col>
            </Row>
            <Form.List name='skus'>
                {(fields) => (
                    <div>
                        {fields.map(({ key, name, ...restField }, idx) => (
                            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align='baseline'>
                                {hasColor && (
                                    <Form.Item {...restField} name={[name, 'color']}>
                                        <Input placeholder='Color' />
                                    </Form.Item>
                                )}
                                <Form.Item {...restField} name={[name, 'size']} rules={[{ required: true, message: 'Missing Size Name' }]}>
                                    <Input placeholder='Size' />
                                </Form.Item>
                                <Form.Item {...restField} name={[name, 'price']} rules={[{ required: true, message: 'Missing Price' }]}>
                                    <InputNumber placeholder='Price' min={0} />
                                </Form.Item>
                                <Form.Item {...restField} name={[name, 'seller_sku']}>
                                    <Input placeholder='Seller SKU' />
                                </Form.Item>
                            </Space>
                        ))}
                    </div>
                )}
            </Form.List>
            <>
                <Row gutter={[20, 20]}>
                    <Col span={6}>
                        <Form.Item label={<b>Variations 1 (Color)</b>} name='colors'>
                            <Input style={{ width: '100%' }} placeholder='Nhập Colors' size='large' readOnly={!!selectedPriceTemp} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={<b>Variations 2 (Size)</b>} name='sizes'>
                            <Input style={{ width: '100%' }} placeholder='Nhập Sizes' size='large' readOnly={!!selectedPriceTemp} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label={<b>SKU</b>} name='sku'>
                            <Input style={{ width: '100%' }} placeholder='Nhập SKU' size='large' />
                        </Form.Item>
                    </Col>
                    {!selectedPriceTemp && (
                        <>
                            <Col span={3}>
                                <Form.Item label={<b>Price</b>} name='price'>
                                    <InputNumber placeholder='Nhập giá sản phẩm' min={0} style={{ width: '100%' }} size='large' />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label={<b>Action</b>}>
                                    <Button type='primary' onClick={generateSKUS} size='large'>
                                        Generate SKUS
                                    </Button>
                                </Form.Item>
                            </Col>
                        </>
                    )}
                </Row>
            </>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type='primary' htmlType='submit' loading={loading} size='large'>
                    Đăng sản phẩm
                </Button>
            </Form.Item>
        </Form>
    )
}

export default CreateProduct
