import { CODE_PERMISSION } from 'const/permission'
import { useAuth } from 'contexts/Authenticate'
import _ from 'lodash'
import { checkPermission } from 'utils/checkPermission'
import { flatStructure } from 'utils/data-structure-utils'

export const treeRoutes = [
    {
        path: '/dashboard',
        title: 'Dashboard',
        icon: 'sliders-h',
        isSidebarHeader: false,
        required: CODE_PERMISSION.DASH_BOARD,
        isAuthenticated: true,
        lazy: () => import('pages/Dashboard')
    },
    {
        title: 'Sản phẩm',
        isSidebarHeader: true
    },
    {
        path: '/quan-ly-san-pham',
        title: 'Quản lý sản phẩm',
        icon: 'store',
        isSidebarHeader: false,
        required: CODE_PERMISSION.PRODUCT_MANAGEMENT,
        isAuthenticated: true,
        lazy: () => import('pages/Dashboard')
    },
    {
        path: '/quan-ly-template',
        title: 'Quản lý Template',
        icon: 'copy',
        isSidebarHeader: false,
        required: CODE_PERMISSION.TEMPLATE_MANAGEMENT,
        isAuthenticated: true,
        lazy: () => import('pages/Dashboard')
    },
    {
        path: '/quan-ly-niche',
        title: 'Quản lý Niche',
        icon: 'code-branch',
        isSidebarHeader: false,
        required: CODE_PERMISSION.NICHE_MANAGEMENT,
        isAuthenticated: true,
        lazy: () => import('pages/Dashboard')
    },
    // {
    //     path: '/upload-san-pham',
    //     title: 'Upload sản phẩm',
    //     icon: 'cloud-upload-alt',
    //     isSidebarHeader: false,
    //     lazy: () => import('pages/Dashboard')
    // },
    // {
    //     title: 'Đơn hàng',
    //     isSidebarHeader: true
    // },
    // {
    //     path: '/quan-ly-don-hang',
    //     title: 'Quản lý đơn hàng',
    //     icon: 'shopping-cart',
    //     isSidebarHeader: false,
    //     lazy: () => import('pages/Dashboard')
    // },
    // {
    //     path: '/quan-ly-yeu-cau-huy',
    //     title: 'Quản lý yêu cầu hủy',
    //     icon: 'shopping-basket',
    //     isSidebarHeader: false,
    //     lazy: () => import('pages/Dashboard')
    // },
    // {
    //     path: '/quan-ly-don-hang-hoan',
    //     title: 'Quản lý đơn trả hàng',
    //     icon: 'shopping-basket',
    //     isSidebarHeader: false,
    //     lazy: () => import('pages/Dashboard')
    // },
    {
        title: 'Listing',
        isSidebarHeader: true
    },
    {
        path: '/quan-ly-listing',
        title: 'Quản lý Listing',
        icon: 'list',
        isSidebarHeader: false,
        required: CODE_PERMISSION.LISTING_MANAGEMENT,
        isAuthenticated: true,
        lazy: () => import('pages/Dashboard')
    },
    {
        path: '/quan-ly-promotion',
        title: 'Promotions',
        icon: 'bolt',
        isSidebarHeader: false,
        required: CODE_PERMISSION.LISTING_MANAGEMENT,
        isAuthenticated: true,
        lazy: () => import('pages/Dashboard')
    },
    // {
    //     path: '/listing-auto-idea',
    //     title: 'Listing Auto Idea',
    //     icon: 'list',
    //     isSidebarHeader: false,
    //     lazy: () => import('pages/Dashboard')
    // },
    {
        title: 'Tài khoản',
        isSidebarHeader: true
    },
    // {
    //     path: '/tai-khoan-shop',
    //     title: 'Thông tin tài khoản shop',
    //     icon: 'user',
    //     isSidebarHeader: false,
    //     lazy: () => import('pages/Dashboard')
    // },
    {
        path: '/tinh-trang-shop',
        title: 'Tình trạng Shop',
        icon: 'heart',
        isSidebarHeader: false,
        required: CODE_PERMISSION.SHOP_HEALTH,
        isAuthenticated: true,
        lazy: () => import('pages/Dashboard')
    },
    // {
    //     path: '/tinh-trang-tk-tiktok',
    //     title: 'Tình trạng TK TikTok',
    //     icon: 'user-check',
    //     isSidebarHeader: false,
    //     lazy: () => import('pages/Dashboard')
    // },
    {
        path: '/ket-noi-shop-tiktok',
        title: 'Kết nối cửa hàng TikTok',
        icon: 'sync',
        isSidebarHeader: false,
        required: CODE_PERMISSION.CONNECT_SHOP,
        isAuthenticated: true,
        lazy: () => import('pages/ShopManagement/ShopHealth')
    },
    {
        path: '/quan-ly-nhan-vien',
        title: 'Quản lý nhân viên',
        icon: 'users',
        isSidebarHeader: false,
        required: CODE_PERMISSION.STAFF_MANAGEMENT,
        isAuthenticated: true,
        lazy: () => import('pages/ShopManagement/ShopHealth')
    },
    {
        path: '/cai-dat-tai-khoan',
        title: 'Cài đặt tài khoản',
        icon: 'cog',
        isSidebarHeader: false,
        required: CODE_PERMISSION.LISTING_MANAGEMENT,
        isAuthenticated: true,
        lazy: () => import('pages/ShopManagement/ShopHealth')
    },
    {
        path: '/cai-dat-template',
        title: 'Cài đặt Template',
        icon: 'cogs',
        isSidebarHeader: false,
        required: CODE_PERMISSION.LISTING_MANAGEMENT,
        isAuthenticated: true,
        lazy: () => import('pages/ShopManagement/ShopHealth')
    }
]

export function filterConfigByPerms(_treeConfig: any = [], perms: any = []) {
    const config: any = []
    _.forEach(_treeConfig, (c: any) => {
        if (c.required !== 'PARENT') {
            const childByPerms = _.find(perms, (p) => p === c.required)
            if (childByPerms) {
                config.push(c)
            }
        } else if (!c.required) {
            config.push(c)
        }
    })
    return config
}

export const flatConfig = flatStructure(treeRoutes)

export const usePermission = () => {
    const { user } = useAuth()


    return {
        user
    }
}
