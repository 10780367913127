import { IProduct, IProductTemplateQueryParams } from 'types/IProduct'
import ApiSender from './ApiSender'
import { IListApiResponse } from 'types/IApiResponse'

export default class ProductTemplateTmpAPI {
    public static getProductTemplateTmp(params: IProductTemplateQueryParams): Promise<IListApiResponse<IProduct>> {
        const route = `/core/user/products-template-tmp`
        return ApiSender.get(route, params)
    }

    public static removeProductTemplateTmp(_id: string) {
        const route = `/core/user/products-template-tmp/${_id}`
        return ApiSender.delete(route)
    }

    public static reuploadProductTemplateTmp(_id: string) {
        const route = `/core/user/products-template-tmp/${_id}/reupload`
        return ApiSender.put(route)
    }

}
